// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbarContainer{
    justify-content: space-around;
}

.navbarItem{
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media only screen and (max-width: 992px) {
    .navbarContainer{
        justify-content: space-between;
    }
    .navbarItem{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .navbarNav{
        align-items: center;
    }
    .navLink{
        border-bottom: 2px solid;
        width: 100%;
        margin-bottom: 10px;
    }
    .navIletisim{
        border-bottom: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/musteriekran/Screens/css/Navbar.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI;QACI,8BAA8B;IAClC;IACA;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;IAC3B;IACA;QACI,mBAAmB;IACvB;IACA;QACI,wBAAwB;QACxB,WAAW;QACX,mBAAmB;IACvB;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".navbarContainer{\n    justify-content: space-around;\n}\n\n.navbarItem{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n@media only screen and (max-width: 992px) {\n    .navbarContainer{\n        justify-content: space-between;\n    }\n    .navbarItem{\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n    }\n    .navbarNav{\n        align-items: center;\n    }\n    .navLink{\n        border-bottom: 2px solid;\n        width: 100%;\n        margin-bottom: 10px;\n    }\n    .navIletisim{\n        border-bottom: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
