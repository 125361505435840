import React, { useMemo, useState } from "react";


// !!! İCONLAR !!!

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// import Modal from "react-modal";

// !!! DATATABLE İMPORTLAR !!!

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";


import UpdateScreen from "../UpdateScreen";

import { deleteKitap } from "../../axios/index";

// import DetayPaneli from "./DetayPaneli";

// MODAL CSS
import "../css/Modal.css"
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ




const Table = props => {


  const [updateBilgiler, setUpdateBilgiler] = useState();

  const updateClickHandler = (updateAlan) => {
    console.log("Güncelle", updateAlan);
    setUpdateBilgiler(updateAlan)
  }



  const handleDelete = async (id, kitapAdi) => {

    console.log(id)
    console.log(kitapAdi)

    const confirmDelete = window.confirm(`${kitapAdi} adlı kurumu silmek istediğinize emin misiniz ?`);
    if (confirmDelete) {
      deleteKitap(id)
        .then((res) => {
          toast.success("Kitap Başarıyla Silindi !")
          console.log(res.data)
        })
        .catch((err) => toast.error("Kitap Silme Başarısız !", err.response.data.message));
    }
  }


  const CustomImageCell = ({ value }) => (
    <img src={value} alt="Kapak" width="70" height="100" />
  );

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);


  const columns = [
    {
      name: "Kitap Fotoğraf",
      selector: (row) => row.kitapKapakFoto.url,
      sortable: true,
      grow: 0.5,
      cell: (row) => <CustomImageCell value={row.kitapKapakFoto ? row.kitapKapakFoto.url : "https://storage.googleapis.com/ornekkitap-bucket/Kitap%20Demo%20Pdfler/kitapbulunamad%C4%B1.png"} />,
    },
    {
      name: "Kitap Adı",
      selector: (row) => row.kitapAdi,
      sortable: true,
      grow: 1,
    },
    {
      name: "Yayın Adı",
      selector: (row) => row.yayinAdi,
      sortable: true,
      grow: 1,
    },
    {
      name: "Sınıf",
      selector: (row) => row.kitapSinif,
      sortable: true,
      grow: 0.5
    },
    {
      name: "Kitap Branş",
      selector: (row) => row.kitapBrans,
      sortable: true,
      grow: 1,
    },
    {
      name: "Kitap Tür",
      selector: (row) => row.kitapTur,
      sortable: true,
      grow: 1,
    },
    {
      name: "İşlemler",
      button: true,
      cell: row =>
      (
        <>


          <div onClick={() => {
            updateClickHandler(row)
            setShow(true)
          }}>
            <UpdateScreen setShow={setShow} show={show} updateBilgiler={updateBilgiler}></UpdateScreen>
          </div>

          <FontAwesomeIcon style={{ fontSize: "20px" }} onClick={() => handleDelete(row._id, row.kitapAdi)} icon={faTrash}></FontAwesomeIcon>

        </>
      )
    }
  ];



  // const [secilenSehir, setSecilenSehir] = React.useState("");
  // const [secilenKurumTur, setSecilenKurumTur] = React.useState("");
  // const [secilenIlce, setSecilenIlce] = React.useState("");
  // const [filterText, setFilterText] = React.useState("");
  // const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
  //   false
  // );
  const [secilenYayinlar, setSecilenYayinlar] = useState([]);
  const [secilenSiniflar, setSecilenSiniflar] = React.useState([]);
  const [secilenBranslar, setSecilenBranslar] = React.useState([]);
  const [secilenKitapTurler, setSecilenKitapTurler] = React.useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  //   console.log(props)
  // const filteredItems = props.data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );


  const filterMethods = [
    (item) => secilenYayinlar.length === 0 || secilenYayinlar.includes(item.yayinAdi),
    (item) => secilenSiniflar.length === 0 ||secilenSiniflar.includes(item.kitapSinif),
    (item) => secilenBranslar.length === 0 || secilenBranslar.includes(item.kitapBrans),
    (item) => secilenKitapTurler.length === 0 ||secilenKitapTurler.includes(item.kitapTur),
    (item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
  ]

  console.log(props)

  const filteredArray = props.data.filter((item) => {
    for (let i = 0; i < filterMethods.length; i++) {
      if (!filterMethods[i](item)) {
        return false
      }
    }
    return true
  })



  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value.toLocaleUpperCase('tr-TR'))}
        onClear={handleClear}
        filterText={filterText}
        setSecilenYayinlar={setSecilenYayinlar}
        setSecilenSiniflar={setSecilenSiniflar}
        setSecilenBranslar={setSecilenBranslar}
        setSecilenKitapTurler={setSecilenKitapTurler}
        secilenYayinlar={secilenYayinlar}
        secilenSiniflar={secilenSiniflar}
        secilenBranslar={secilenBranslar}
        secilenKitapTurler={secilenKitapTurler}
      />
    );
  }, [filterText, resetPaginationToggle,secilenYayinlar,secilenSiniflar, secilenBranslar,secilenKitapTurler]);

  return (
    <DataTable
      title="Kitap Listesi"
      columns={columns}
      data={filteredArray}
      defaultSortField="name"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      paginationPerPage={50}
      noDataComponent="Herhangi bir veriye ulaşılmadı"

      paginationComponentOptions={{
        rowsPerPageText: "Sayfa başına kayıt sayısı:"
      }}
    />
  );
};

export default Table;
