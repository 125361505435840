import React, { useState } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useTheme, Box, IconButton, InputBase } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useProSidebar } from "react-pro-sidebar";


//Yönlendirme
import { useNavigate  } from "react-router-dom";

import "./css/Topbar.css"

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const [profilBilgiAc,setProfilBilgiAc] = useState("");

  //Yönlendirme
  const navigate  = useNavigate();
  // Açılan user bilgi ekranı
  const isim = JSON.parse(localStorage.getItem('user'));

  const openUserFunc = () => {
    if(profilBilgiAc === true){
      setProfilBilgiAc(false)
    }
    else{
      setProfilBilgiAc(true)
    }
  }

  //

  const cikisYap = () =>{
    navigate("/aciadmin"); // Yönlendirme yapılacak URL'yi belirtin
    window.location.reload();
    localStorage.removeItem("user");
  }

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box display="flex">
        {broken && !rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          p={0.2}
          borderRadius={1}
        >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Ara" />
          <IconButton type="button">
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (

            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>


        <div className="openBoxDiv">
          <IconButton  onClick={openUserFunc} >
            <PersonOutlinedIcon />
          </IconButton>
          
          {profilBilgiAc === true && isim !== null ? 
            <Box className="openUserBox">
                 <h6>Kullanıcı Türü: {isim.userType}</h6>
                 <h6>İsim Soyisim: {isim.fullname}</h6>
                 <h6>E-posta: {isim.email}</h6>
                 <button className="mt-2 btn btn-danger cikisyapButton" onClick={cikisYap}>Çıkış Yap</button>
              
            </Box>
            :
              null
          }
        </div>


        {broken && rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}

      </Box>
    </Box>
  );
};

export default Topbar;
