import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { register } from "../axios";

// SELECT OPTİON'DA KULLANILAN İLLER
import iller from "./service/iller.json";
import siniflar from "./service/sınıflar.json";
import branslar from "./service/branslar.json";
import kitapTur from "./service/kitapTur.json";
import yayinlar from "./service/yayinlar.json";
// MODAL CSS
import "./css/Modal.css";
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';


const AddScreen = (props) => {

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    formDataSıfırla(); // Modal kapandığında formu sıfırlıyoruz.
  };
  const handleShow = () => setShow(true);


  const sehirler = []
  for (let i = 0; i < iller.length; i++) {
    sehirler.push(iller[i].il_adi)
  }


  const handleImage = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, kitapKapakFoto: file })
  }
  const handlePdf = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, demoKitapPdf: file })
  }



  // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ
  const [formData, setFormData] = useState({
    kitapAdi: "",
    kitapSinif: "",
    kitapBrans: "",
    kitapTur: "",
    kitapCikisYili: "",
    kitapYazari: "",
    kitapSayfaSayisi: "",
    kitapSoruSayisi: "",
    yayinAdi: "",
    demoKitapPdf: [],
    cözümVideoLink: "",
    kitapKapakFoto: [],
  });

  const formDataSıfırla = () => {
    setFormData({
      kitapAdi: "",
      kitapSinif: "",
      kitapBrans: "",
      kitapTur: "",
      kitapCikisYili: "",
      kitapYazari: "",
      kitapSayfaSayisi: "",
      kitapSoruSayisi: "",
      yayinAdi: "",
      demoKitapPdf: [],
      cözümVideoLink: "",
      kitapKapakFoto: [],
    });
  }



  return (
    <>
      <Button style={{ fontSize: "18px", fontWeight: "500" }} variant="primary" onClick={handleShow}>
        <FontAwesomeIcon className="me-3" icon={faPlus} size="1x" />
        Yeni Oluştur
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Kitap Ekleme</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={9}>
                <Form
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const toastId = toast.loading("Kitap oluşturuluyor...");
                    register(formData)
                      .then((res) => {
                        // handleClose() // Kitap başarılı bir şekilde oluşturulursa modalı kapatma
                        toast.update(toastId, { render: 'Kitap başarıyla oluşturuldu ! 👌', type: toast.TYPE.SUCCESS, isLoading: false });
                        // formDataSıfırla()
                        toast.dismiss();
                      })
                      .catch((err) => {
                        toast.update(toastId, { render: 'Kitap oluşturma başarısız ! 🤯', type: toast.TYPE.ERROR, isLoading: false });
                        toast.dismiss();
                      });
                  }}
                >

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kapak Fotoğraf</Form.Label>
                    <Form.Control
                      onChange={handleImage}
                      type="file"
                      name="kitapKapakFoto"
                      placeholder="Kurum Fotoğrafı Ekleyiniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kitap Adı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFormData({ ...formData, kitapAdi: e.target.value.toLocaleUpperCase('tr-TR') })
                      }
                      type="name"
                      placeholder="Kitap Adını Giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label> Kitap Yayın</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setFormData({ ...formData, yayinAdi: e.target.value })
                    }>
                      <option value="" disabled>Yayın Seç</option>
                      {yayinlar.map((yayinlarr) => {
                        return (
                          <option key={yayinlarr.yayinAdi} value={yayinlarr.yayinAdi}>{yayinlarr.yayinAdi}</option>
                        )
                      })}

                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label> Sınıf</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setFormData({ ...formData, kitapSinif: e.target.value })
                    }>
                      <option value="" disabled>Sınıf Seç</option>
                      {siniflar.map((siniflarr) => {
                        return (
                          <option key={siniflarr.sinif} value={siniflarr.sinif}>{siniflarr.sinif}</option>
                        )
                      })}

                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Branş</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setFormData({ ...formData, kitapBrans: e.target.value.toLocaleUpperCase('tr-TR') })
                    }>
                      <option value="" disabled>Branş Seç</option>
                      {branslar.map((branslarr) => {
                        return (
                          <option key={branslarr.brans} value={branslarr.brans}>{branslarr.brans}</option>
                        )
                      })}


                    </Form.Select>
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kitap Tür</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setFormData({ ...formData, kitapTur: e.target.value.toLocaleUpperCase('tr-TR') })
                    }>
                      <option value="" disabled>Kitap Türünü Seç</option>
                      {kitapTur.map((kitapTurr) => {
                        return (
                          <option key={kitapTurr.kitapTur} value={kitapTurr.kitapTur}>{kitapTurr.kitapTur}</option>
                        )
                      })}


                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Sayfa Sayısı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFormData({ ...formData, kitapSayfaSayisi: e.target.value })
                      }
                      type="number"
                      placeholder="Sayfa Sayısını Giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Soru Sayısı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFormData({ ...formData, kitapSoruSayisi: e.target.value })
                      }
                      type="number"
                      placeholder="Soru Sayısını Giriniz"
                    />
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kitap Çıkış Yılı</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setFormData({ ...formData, kitapCikisYili: e.target.value.toLocaleUpperCase('tr-TR') })
                    }>
                      <option value="" disabled>Kitap Çıkış Yılı Seç</option>
                      <option value="2023">2023</option>

                    </Form.Select>
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Kitap Yazarı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFormData({ ...formData, kitapYazari: e.target.value })
                      }
                      type="name"
                      placeholder="Kitap Yazarını Giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Video Çözüm Link</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFormData({ ...formData, cözümVideoLink: e.target.value })
                      }
                      type="name"
                      placeholder="Video Çözüm Linki Giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kitap Demo</Form.Label>
                    <Form.Control
                      onChange={handlePdf}
                      type="file"
                      name="demoKitapPdf"
                      placeholder="Demo Pdf Ekleyiniz"
                    />
                  </Form.Group>



                  <Form.Group className="d-grid">


                    <Button variant="primary" type="submit" className="mt-4 w-100">
                      Kayıt Ekle
                    </Button>

                    <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                      Vazgeç
                    </Button>

                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>


        </Modal.Body>


      </Modal>
    </>
  );






};
export default AddScreen;
