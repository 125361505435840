import React, { useState } from "react";
import styled from "styled-components";
import yayinlar from "../service/yayinlar.json"
import siniflar from "../service/sınıflar.json"
import branslar from "../service/branslar.json"
import kitapTurler from "../service/kitapTur.json"

// !!! İCONLAR !!!

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import AddScreen from "../AddScreen";
import { Button } from "react-bootstrap";
import "../Table/filterComponent.css"


const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const FilterComponent = ({ filterText, onFilter, onClear, setSecilenYayinlar, setSecilenSiniflar, setSecilenBranslar, setSecilenKitapTurler, secilenYayinlar, secilenSiniflar, secilenBranslar, secilenKitapTurler }) => {


  const [expanded, setExpanded] = useState(false);
  const [expandedSinif, setExpandedSinif] = useState(false);
  const [expandedBrans, setExpandedBrans] = useState(false);
  const [expandedKitapTur, setExpandedKitapTur] = useState(false);

  function showCheckboxes() {
    const checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "flex";
      checkboxes.style.flexDirection = "column";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  }

  function showCheckboxesSinif() {
    const checkboxesSinif = document.getElementById("checkboxesSinif");
    if (!expandedSinif) {
      checkboxesSinif.style.display = "flex";
      checkboxesSinif.style.flexDirection = "column";
      setExpandedSinif(true);
    } else {
      checkboxesSinif.style.display = "none";
      setExpandedSinif(false);
    }
  }

  function showCheckboxesBrans() {
    const checkboxesBrans = document.getElementById("checkboxesBrans");
    if (!expandedBrans) {
      checkboxesBrans.style.display = "flex";
      checkboxesBrans.style.flexDirection = "column";
      setExpandedBrans(true);
    } else {
      checkboxesBrans.style.display = "none";
      setExpandedBrans(false);
    }
  }

  function showCheckboxesKitapTur() {
    const checkboxesKitapTur = document.getElementById("checkboxesKitapTur");
    if (!expandedKitapTur) {
      checkboxesKitapTur.style.display = "flex";
      checkboxesKitapTur.style.flexDirection = "column";
      setExpandedKitapTur(true);
    } else {
      checkboxesKitapTur.style.display = "none";
      setExpandedKitapTur(false);
    }
  }

  const handleCheckboxChangeYayin = (event, yayinAdi) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSecilenYayinlar([...secilenYayinlar, yayinAdi]); // Yeni yayını ekleyin
    } else {
      setSecilenYayinlar(secilenYayinlar.filter((yayin) => yayin !== yayinAdi)); // Yayını çıkarın
    }
  };

  const handleCheckboxChangeSinif = (event, sinifAdi) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSecilenSiniflar([...secilenSiniflar, sinifAdi]); // Yeni yayını ekleyin
    } else {
      setSecilenSiniflar(secilenSiniflar.filter((sinif) => sinif !== sinifAdi)); // Yayını çıkarın
    }
  };

  const handleCheckboxChangeBrans = (event, brans) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSecilenBranslar([...secilenBranslar, brans]); // Yeni yayını ekleyin
    } else {
      setSecilenBranslar(secilenBranslar.filter((branss) => branss !== brans)); // Yayını çıkarın
    }
  };

  const handleCheckboxChangeKitapTur = (event, kitapTur) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSecilenKitapTurler([...secilenKitapTurler, kitapTur]); // Yeni yayını ekleyin
    } else {
      setSecilenKitapTurler(secilenKitapTurler.filter((kitapTurr) => kitapTurr !== kitapTur)); // Yayını çıkarın
    }
  };

  const filterReset = (() => {
    setSecilenYayinlar([]);
    setSecilenSiniflar([]);
    setSecilenBranslar([]);
    setSecilenKitapTurler([]);

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

  })

  return (

    <div style={{ width: "100%" }}>


      <div className="search-and-add-area">
        <div className="searchFilterAlan">
          <FontAwesomeIcon className="headerSearchIcon" icon={faSearch}></FontAwesomeIcon>
          <Input
            id="search"
            type="text"
            placeholder="Tabloda Ara..."
            className="headerFilter"
            value={filterText}
            onChange={onFilter}
          />
          <ClearButton className="headerClear" onClick={onClear}><FontAwesomeIcon icon={faX} /></ClearButton>

        </div>

        <div>
          {/* <FontAwesomeIcon style={{color:"black"}} icon={faX}></FontAwesomeIcon>
        <input className="btn btn-primary" type="button" value="Filtre Kaldır" onClick={filterReset}/> */}
          <Button style={{ fontSize: "18px", fontWeight: "500" }} variant="primary" onClick={filterReset}>
            <FontAwesomeIcon className="me-3" icon={faX} size="1x" />
            Filtre Kaldır
          </Button>
        </div>

        <div>
          <AddScreen></AddScreen>
        </div>
      </div>


      <div className="selectAlan">


        <div className="selectBox headerSelect" onClick={showCheckboxes}>
          <select>
            <option>
              {secilenYayinlar.length === 0
                ? "Yayın Seç"
                : `${secilenYayinlar.length} Yayın Filtresi Aktif`}
            </option>
          </select>
          <div className="overSelect"></div>
          <div id="checkboxes">

{yayinlar.map((e) => {
  return (
    <label htmlFor={e.yayinAdi} key={e.yayinAdi}>

      <input type="checkbox" value={e.yayinAdi} id={e.yayinAdi} onChange={(event) => handleCheckboxChangeYayin(event, e.yayinAdi)} />{e.yayinAdi}</label>
  );
})}

</div>
        </div>

        
        <div className="selectBoxSinif headerSelect" onClick={showCheckboxesSinif}>
          <select>
            <option>
              {secilenSiniflar.length === 0
                ? "Sınıf Seç"
                : `${secilenSiniflar.length} Sınıf Filtresi Aktif`}
            </option>
          </select>
          <div className="overSelect"></div>
          <div id="checkboxesSinif">

            {siniflar.map((e) => {
              return (
                <label htmlFor={e.sinif} key={e.sinif}>

                  <input type="checkbox" value={e.sinif} id={e.sinif} onChange={(event) => handleCheckboxChangeSinif(event, e.sinif)} />{e.sinif}</label>
              );
            })}

          </div>
        </div>


        <div className="selectBoxBrans headerSelect" onClick={showCheckboxesBrans}>
          <select>
            <option>
              {secilenBranslar.length === 0
                ? "Branş Seç"
                : `${secilenBranslar.length} Branş Filtresi Aktif`}
            </option>
          </select>
          <div className="overSelect"></div>
          <div id="checkboxesBrans">

{branslar.map((e) => {
  return (
    <label htmlFor={e.brans} key={e.brans}>

      <input type="checkbox" value={e.brans} id={e.brans} onChange={(event) => handleCheckboxChangeBrans(event, e.brans)} />{e.brans}</label>
  );
})}

</div>
        </div>

    
        <div className="selectBoxKitapTur headerSelect" onClick={showCheckboxesKitapTur}>
          <select>
            <option>
              {secilenKitapTurler.length === 0
                ? "Kitap Türü Seç"
                : `${secilenKitapTurler.length} Kitap Türü Filtresi Aktif`}
            </option>
          </select>
          <div className="overSelect"></div>
            <div id="checkboxesKitapTur">

{kitapTurler.map((e) => {
  return (
    <label htmlFor={e.kitapTur} key={e.kitapTur} >

      <input type="checkbox" value={e.kitapTur} id={e.kitapTur} onChange={(event) => handleCheckboxChangeKitapTur(event, e.kitapTur)} />{e.kitapTur}</label>
  );
})}

</div>
        </div>

      

      </div>
    </div>




  )
};

export default FilterComponent;
