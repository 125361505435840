import React, { useState, useRef, useEffect } from "react";
import "./css/FeedbackScreen.css";
import { Form, Button, FormCheck } from "react-bootstrap";
import { feedbackAdd } from "../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReCAPTCHA from "react-google-recaptcha";

import mailPhoto from "./img/feedbackmailphoto.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMailBulk, faUser } from '@fortawesome/free-solid-svg-icons';

import { Tilt } from 'react-tilt'

const defaultOptions = {
  reverse: false,  // reverse the tilt direction
  max: 35,     // max tilt rotation (degrees)
  perspective: 1000,   // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.1,    // 2 = 200%, 1.5 = 150%, etc..
  speed: 1000,   // Speed of the enter/exit transition
  transition: true,   // Set a transition on enter/exit.
  axis: null,   // What axis should be disabled. Can be X or Y.
  reset: true,    // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
}

const FeedbackScreen = () => {

  const [captchaResponse, setCaptchaResponse] = useState('');

  const [feedbackFormData, setFeedbackFormData] = useState({
    sikayetTuru: "",
    sikayetText: "",
    sikayetYapanınIsım: "",
    sikayetYapanınTelefonNo: "",
    sikayetYapanınEposta: "",
    sikayetTarihi: ""
  });

  const adRef = useRef();
  const telefonNoRef = useRef();
  const epostaRef = useRef();
  const istekTurRef1 = useRef();
  const istekTurRef2 = useRef();
  const istekTurRef3 = useRef();
  const istekTurRef4 = useRef();
  const mesajRef = useRef();


  // Form Başarılı bir şekilde gönderildikten sonra tüm formu resetler.
  const refReset = () => {
    adRef.current.value = "";
    telefonNoRef.current.value = "";
    epostaRef.current.value = "";
    istekTurRef1.current.checked = false;
    istekTurRef2.current.checked = false;
    istekTurRef3.current.checked = false;
    istekTurRef4.current.checked = false;
    mesajRef.current.value = "";

    setFeedbackFormData({
      sikayetTuru: "",
      sikayetText: "",
      sikayetYapanınIsım: "",
      sikayetYapanınTelefonNo: "",
      sikayetYapanınEposta: "",
      sikayetTarihi: ""
    });
  }

  // Güncel Tarihi bulur
  const anlıkTarihBul = () => {
    return new Date().toLocaleString('tr-TR');
  };

  
  // Formu post etme
  const handleSubmit = (e) => {
    e.preventDefault();

      // CAPTCHA kontrolü
  if (!captchaResponse) {
    toast.error("Lütfen CAPTCHA'yı tamamlayın!");
    return;
  }
  else{
    const currentDate = anlıkTarihBul();

    const formData = {
      ...feedbackFormData,
      sikayetTarihi: currentDate
    };

    feedbackAdd(formData)
      .then((res) => {
        toast.success("Form Başarıyla Gönderildi !")
        refReset();

      })
      .catch((err) => {
        if (formData.sikayetYapanınIsım === "") {
          toast.warning("Ad kısmı boş kalamaz !");
        }
        if (formData.sikayetYapanınTelefonNo.length >= 12) {
          toast.warning("Telefon Numarası en fazla 11 haneli olmalıdır");
        }
        if (formData.sikayetYapanınEposta === "") {
          toast.warning("E-Posta Kısmı boş kalamaz !")
        }
        if (formData.sikayetTuru === "") {
          toast.warning("Mesaj türü boş kalamaz !")
        }
        if (formData.sikayetText === "") {
          toast.warning("Mesaj Kısmı boş kalamaz !")
        }
        console.log(err.response.data.message);
      });
  }};


  return (
    <div className="container iletisimAlan">

      <div className="row mt-5 bg-light iletisimFormAlan">

        <div className="col-md-6 text-center d-flex flex-column justify-content-evenly">

          <Tilt className="feedbackFormLeftSideImageArea" options={defaultOptions}>
            <img alt="Form Mesaj Kutusu" className="feedbackImageAreaImage" src={mailPhoto} />
          </Tilt>

        </div>


        <div className="col-md-6 feedbackFormRightSide">
          <h3 className="feedbackFormContactWeH3">Bize Ulaşın</h3>
          <Form className="feedbackForm" onSubmit={handleSubmit}>

            <div className="feedbackFormInfos">
              <div>
                <label>*Ad Soyad</label>
                <div className="feedbackFormKapsayıcı">
                  <FontAwesomeIcon className="feedbackKapsayıcıIcon" icon={faUser}></FontAwesomeIcon>
                  <Form.Control ref={adRef} type="text" onChange={(e) => {
                    setFeedbackFormData({ ...feedbackFormData, sikayetYapanınIsım: e.target.value })
                  }}></Form.Control>
                </div>
              </div>

              <div>
                <label>*E-posta</label>
                <div className="feedbackFormKapsayıcı">
                  <FontAwesomeIcon className="feedbackKapsayıcıIcon" icon={faMailBulk}></FontAwesomeIcon>
                  <Form.Control ref={epostaRef} type="email" onChange={(e) => {
                    setFeedbackFormData({ ...feedbackFormData, sikayetYapanınEposta: e.target.value })
                  }}></Form.Control>
                </div>
              </div>

              <div>
                <label>Telefon (İsteğe Bağlı)</label>
                <div className="feedbackFormKapsayıcı">
                  <FontAwesomeIcon className="feedbackKapsayıcıIcon" icon={faPhone}></FontAwesomeIcon>
                  <Form.Control ref={telefonNoRef} type="number" onChange={(e) => {
                    setFeedbackFormData({ ...feedbackFormData, sikayetYapanınTelefonNo: e.target.value })
                  }}></Form.Control>
                </div>

                <div className="mt-3">
                  <label>*Mesaj Türü:</label>
                </div>

              </div>
            </div>

            <div className="feedbackFormBacks ">
              <div>
                <label>İstek</label>
                <FormCheck ref={istekTurRef1} type="radio" value="istek" name="sikayetTuru" onClick={(e) => {
                  setFeedbackFormData({ ...feedbackFormData, sikayetTuru: e.target.value })
                }}></FormCheck>
              </div>

              <div>
                <label>Öneri</label>
                <FormCheck ref={istekTurRef2} type="radio" value="oneri" name="sikayetTuru" onClick={(e) => {
                  setFeedbackFormData({ ...feedbackFormData, sikayetTuru: e.target.value })
                }}></FormCheck>
              </div>

              <div>
                <label>Şikayet</label>
                <FormCheck ref={istekTurRef3} type="radio" value="sikayet" name="sikayetTuru" onClick={(e) => {
                  setFeedbackFormData({ ...feedbackFormData, sikayetTuru: e.target.value })
                }}></FormCheck>
              </div>

              <div>
                <label>Soru</label>
                <FormCheck ref={istekTurRef4} type="radio" value="soru" name="sikayetTuru" onClick={(e) => {
                  setFeedbackFormData({ ...feedbackFormData, sikayetTuru: e.target.value })
                }}></FormCheck>
              </div>
            </div>

            <div className="feedbackFormTextarea ">
              <p>*Mesajınız:</p>
              <Form.Control ref={mesajRef} as="textarea" style={{ height: "150px", width: "80%" }} onChange={(e) => {
                setFeedbackFormData({ ...feedbackFormData, sikayetText: e.target.value })
              }} />
            </div>

            <div className="d-flex justify-content-center mt-3">
              <ReCAPTCHA sitekey="6LeKUc4pAAAAAA3QGr0-2TyDNeLV1Z0M8v9MJuvw"
              onChange={(response) => setCaptchaResponse(response)} />
            </div>

            <div className="feedbackFormSubmit">
              <Button className="me-4 feedbackFormSubmitButton" type="submit">Gönder</Button>
              <Button className="btn-secondary" type="reset">Sıfırla</Button>
            </div>

            <ToastContainer />
          </Form>

        </div>
      </div>

    </div>
  )
};


export default FeedbackScreen;
