import React from "react";
import { setElemanDelete } from "../../axios/index";

// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const SetJustsetDeleteScreen = (props) => {

    const handleDelete = async (yayinId, setId, setAdi) => {
        console.log(
            `Yayın Id: ${yayinId},
            Set Id: ${setId},
            Set Adı: ${setAdi}`
        );
    
        const confirmDelete = window.confirm(`${setAdi} adlı seti silmek istediğinize emin misiniz ?`);
    
        if (confirmDelete) {
            try {
                const res = await setElemanDelete(yayinId, setId);
                toast.success(`${setAdi} adlı set elemanı başarıyla silindi!`);
                console.log(res.data);
            } catch (err) {
                console.error(`Set eleman silme başarısız`);
            }
        }
    }
    
      
    return (
        <>
            <FontAwesomeIcon
                style={{ fontSize: "20px", color: "black" }}
                className="me-3"
                icon={faTrash}
                size="1x"
                value={"a"}
                onClick={() => handleDelete(props.setler._id,props.bilgilersetmap._id,props.bilgilersetmap.setAdi)}
            />
        </>
    );


};
export default SetJustsetDeleteScreen;
