//React paketleri
import { useState, useEffect } from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

// Yönlendirme paketi
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

// Tema Rengi
import { ColorModeContext, useMode } from "./admin/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

// Css dosyası
import "./index.css";

// Müşteri Screenleri
import Header from "./musteriekran/Components/Header"; 
import Footer from "./musteriekran/Components/Footer";
import KitapDetay from "./musteriekran/Components/KitapDetay";
import KitapDemo from "./musteriekran/Components/KitapDemo";
import KurumListe from "./musteriekran/Screens/KK_Add.js";
import FeedbackScreen from "./musteriekran/Screens/FeedbackScreen";


// Admin paneli Screenleri
import { MyProSidebarProvider } from "./admin/pages/global/sidebar/sidebarContext";
import Topbar from "./admin/pages/global/Topbar.jsx";

import LoginScreen from "./adminekran/Screens/LoginScreen";
import AdminAnaSayfa from "./adminekran/Screens/HomeScreen.js";
import KurumListeAdmin from "./adminekran/Screens/KK_Add";
import AddScreen from "./adminekran/Screens/AddScreen.js";
import FeedbackAdmin from "./adminekran/Screens/FeedbackAdmin";
import SetScreen from "./adminekran/Screens/setScreens/SetScreen";

// Müşteri panel screenler
import "./musteriekran/Screens/css/MusteriHomeScreen.css"
import HomeScreen from "./musteriekran/Screens/HomeScreen";
import ScrollToTop from "./musteriekran/Components/ScrollToTop";

import ReactGA from "react-ga";

// React onepage app hazırlama
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);



function initialGA(){
  ReactGA.initialize("G-28S9VR2RMS");
  ReactGA.pageview(window.location);
}

const App = () => {
  const [user, setUser] = useState(null);
  const [theme, colorMode] = useMode();

  const location = useLocation();
  const [pathname, setPathName] = useState(location.pathname);

  initialGA();

  useEffect(() => {
    setPathName(location.pathname);
  
    // localStorage'da user anahtarının değerini kontrol et
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(storedUser); // localStorage'dan gelen değeri kullanıcı olarak ayarla
    }
  }, [location, setUser, setPathName]);



  return (
    
    <div>

      
      {pathname.startsWith("/aciadmin") ? (

        <div className="adminScreen">
          {user === null ? (
            <LoginScreen setUser={setUser} />
          ) : (
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <MyProSidebarProvider user={user}>
                  <div className="container">
                    <div style={{ height: "100%", width: "100%" }}>
                      <main style={{ height: "100%", width: "100%" }}>
                        <Topbar user={user} />

                        <Routes>
                          <Route exact path="/aciadmin" element={<AdminAnaSayfa user={user} />} />
                          <Route path="/aciadmin/liste" element={<KurumListeAdmin user={user} />} />
                          <Route path="/aciadmin/liste/add" element={<AddScreen></AddScreen>} />
                          <Route path="/aciadmin/liste/update/:id" element={<KurumListeAdmin></KurumListeAdmin>} />
                          <Route path="/aciadmin/setler" element={<SetScreen/>} />
                          <Route path="/aciadmin/messages" element={<FeedbackAdmin></FeedbackAdmin>} />
                        </Routes>
                      </main>
                    </div>
                  </div>
                </MyProSidebarProvider>
              </ThemeProvider>
            </ColorModeContext.Provider>
          )}
        </div>


      ) : (
        <div className="musteriScreen background">
        <Routes>
          <Route path="/kitapdetay/kitapdemo/:kitapId" element={<KitapDemo/>} />
          <Route path="*" element={
            <>
              <Header />
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/kitaplar" element={<KurumListe/>} />
                <Route path="/kitapdetay/:kitapId" element={<KitapDetay/>} />
                <Route path="/iletisim" element={<FeedbackScreen />} />
              </Routes>
              <Footer/>
            </>
          }/>
        </Routes>
      </div>
      )
      }

    </div >
  );
};

root.render(
  <StrictMode>
    <BrowserRouter>
    <ScrollToTop/>
      <App />
    </BrowserRouter>
  </StrictMode>
);
