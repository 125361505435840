import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { update } from "../axios";

// SELECT OPTİON'DA KULLANILANLAR
import siniflar from "./service/sınıflar.json";
import branslar from "./service/branslar.json";
import kitapTur from "./service/kitapTur.json";
import yayinlar from "./service/yayinlar.json";
// MODAL CSS 
import "./css/Modal.css";
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons';


const UpdateScreen = (props) => {

  const [kitapKapakFotoVal, setKitapKapakFotoVal] = useState();
  const [kitapAdiVal, setKitapAdiVal] = useState();
  const [yayinAdiVal, setYayinAdiVal] = useState();
  const [kitapSinifVal, setKitapSinifVal] = useState();
  const [kitapBransVal, setKitapBransVal] = useState();
  const [kitapTurVal, setKitapTurVal] = useState();
  const [kitapSayfaSayisiVal, setKitapSayfaSayisiVal] = useState();
  const [kitapSoruSayisiVal, setKitapSoruSayisiVal] = useState();
  const [kitapCikisYiliVal, setKitapCikisYiliVal] = useState();
  const [kitapYazariVal, setKitapYazariVal] = useState();
  const [cözümVideoLinkVal, setCözümVideoLinkVal] = useState();
  const [demoKitapPdfVal, setDemoKitapPdfVal] = useState();


  useEffect(() => {
    if (props.updateBilgiler !== undefined) {
      setKitapAdiVal(props.updateBilgiler.kitapAdi)
      setYayinAdiVal(props.updateBilgiler.yayinAdi)
      setKitapSinifVal(props.updateBilgiler.kitapSinif)
      setKitapBransVal(props.updateBilgiler.kitapBrans)
      setKitapTurVal(props.updateBilgiler.kitapTur)
      setKitapSayfaSayisiVal(props.updateBilgiler.kitapSayfaSayisi)
      setKitapSoruSayisiVal(props.updateBilgiler.kitapSoruSayisi)
      setKitapCikisYiliVal(props.updateBilgiler.kitapCikisYili)
      setKitapYazariVal(props.updateBilgiler.kitapYazari)
      setCözümVideoLinkVal(props.updateBilgiler.cözümVideoLink)

      if (props.updateBilgiler.demoKitapPdf && props.updateBilgiler.demoKitapPdf.url) {
        setDemoKitapPdfVal(props.updateBilgiler.demoKitapPdf.url);
      } else {
        setDemoKitapPdfVal("https://res.cloudinary.com/djjt937xm/image/upload/v1689322941/acikurumliste_mqrcpa.png");
      }
      if (props.updateBilgiler.kitapKapakFoto && props.updateBilgiler.kitapKapakFoto.url) {
        setKitapKapakFotoVal(props.updateBilgiler.kitapKapakFoto.url);
      } else {
        setKitapKapakFotoVal("https://res.cloudinary.com/djjt937xm/image/upload/v1689322941/acikurumliste_mqrcpa.png");
      }

    }
  }, [props.updateBilgiler])

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
  };


  // BU SAYFADA updatedFormData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ
  const [updatedFormData, setUpdatedFormData] = useState({
    kitapAdi: "",
    kitapSinif: "",
    kitapBrans: "",
    kitapTur: "",
    kitapCikisYili: "",
    kitapYazari: "",
    kitapSayfaSayisi: "",
    kitapSoruSayisi: "",
    yayinAdi: "",
    demoKitapPdf: [],
    cözümVideoLink: "",
    kitapKapakFoto: [],
  });



  const handleImage = (e) => {
    const file = e.target.files[0];
    setUpdatedFormData({ ...updatedFormData, kitapKapakFoto: file })
    console.log(file)
  }
  const handlePdf = (e) => {
    const file = e.target.files[0];
    setUpdatedFormData({ ...updatedFormData, demoKitapPdf: file })
    console.log(file)
  }

  console.log(props)
  return (
    <>
      <FontAwesomeIcon style={{ fontSize: "20px" }} onClick={handleShow} className="me-3" icon={faCog} size="1x" />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Kitap Güncelle</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={9}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const toastId = toast.loading("Kitap güncelleniyor...");
                    update(props.updateBilgiler._id, updatedFormData)
                      .then((res) => {
                        handleClose()
                        toast.update(toastId, { render: 'Kitap başarıyla güncellendi ! 👌', type: toast.TYPE.SUCCESS, isLoading: false });
                        console.log(res.data)
                        toast.dismiss();
                      })
                      .catch((err) => {
                        toast.update(toastId, { render: 'Kitap güncelleme başarısız ! 🤯', type: toast.TYPE.ERROR, isLoading: false });
                        toast.dismiss();
                      });

                  }}
                >

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kapak Fotoğraf</Form.Label>
                    <Form.Control
                      onChange={handleImage}
                      type="file"
                      name="kitapKapakFoto"
                      placeholder="Kurum Fotoğrafı Ekleyiniz"
                    />

                    {props.updateBilgiler !== undefined ?
                      <div className="d-flex justify-content-center">
                        <img crossOrigin="anonymous" alt="Kitap Kapak Fotoğrafı" style={{ width: "50%", height: "300px", marginTop: "20px" }} src={props.updateBilgiler.kitapKapakFoto ? props.updateBilgiler.kitapKapakFoto.ucUrl : "https://drive.google.com/uc?id=1eaMYbXBSNYonurQEKx9V0pDPV4NHIBh9"} />
                      </div>
                      : null}

                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kitap Adı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setUpdatedFormData({ ...updatedFormData, kitapAdi: e.target.value.toLocaleUpperCase('tr-TR') }) +
                        setKitapAdiVal(e.target.value)
                      }
                      type="name"
                      placeholder="Kitap Adını Giriniz"
                      value={kitapAdiVal}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label> Kitap Yayın</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setUpdatedFormData({ ...updatedFormData, yayinAdi: e.target.value }) +
                      setYayinAdiVal(e.target.value)
                    }>
                      <option value="" disabled>{yayinAdiVal}</option>
                      {yayinlar.map((yayinlarr) => {
                        return (
                          <option key={yayinlarr.yayinAdi} value={yayinlarr.yayinAdi}>{yayinlarr.yayinAdi}</option>
                        )
                      })}

                    </Form.Select>
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label> Sınıf</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setUpdatedFormData({ ...updatedFormData, kitapSinif: e.target.value }) +
                      setKitapSinifVal(e.target.value)
                    }>
                      <option value="" disabled>{kitapSinifVal}</option>
                      {siniflar.map((siniflarr) => {
                        return (
                          <option key={siniflarr.sinif} value={siniflarr.sinif}>{siniflarr.sinif}</option>
                        )
                      })}

                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Branş</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setUpdatedFormData({ ...updatedFormData, kitapBrans: e.target.value.toLocaleUpperCase('tr-TR') }) +
                      setKitapBransVal(e.target.value)
                    }>
                      <option value="" disabled>{kitapBransVal}</option>
                      {branslar.map((branslarr) => {
                        return (
                          <option key={branslarr.brans} value={branslarr.brans}>{branslarr.brans}</option>
                        )
                      })}


                    </Form.Select>
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kitap Tür</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setUpdatedFormData({ ...updatedFormData, kitapTur: e.target.value.toLocaleUpperCase('tr-TR') }) + setKitapTurVal(e.target.value)
                    }>
                      <option value="" disabled>{kitapTurVal}</option>
                      {kitapTur.map((kitapTurr) => {
                        return (
                          <option key={kitapTurr.kitapTur} value={kitapTurr.kitapTur}>{kitapTurr.kitapTur}</option>
                        )
                      })}


                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Sayfa Sayısı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setUpdatedFormData({ ...updatedFormData, kitapSayfaSayisi: e.target.value }) +
                        setKitapSayfaSayisiVal(e.target.value)
                      }
                      type="number"
                      placeholder="Sayfa Sayısını Giriniz"
                      value={kitapSayfaSayisiVal}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Soru Sayısı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setUpdatedFormData({ ...updatedFormData, kitapSoruSayisi: e.target.value }) +
                        setKitapSoruSayisiVal(e.target.value)
                      }
                      type="number"
                      placeholder="Soru Sayısını Giriniz"
                      value={kitapSoruSayisiVal}
                    />
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kitap Çıkış Yılı</Form.Label>
                    <Form.Select defaultValue="" className="ps-5" onChange={(e) =>
                      setUpdatedFormData({ ...updatedFormData, kitapCikisYili: e.target.value.toLocaleUpperCase('tr-TR') }) + setKitapCikisYiliVal(e.target.value)
                    }>
                      <option value="" disabled>{kitapCikisYiliVal}</option>
                      <option value="2023">2023</option>

                    </Form.Select>
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Kitap Yazarı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setUpdatedFormData({ ...updatedFormData, kitapYazari: e.target.value }) + setKitapYazariVal(e.target.value)
                      }
                      type="name"
                      placeholder="Kitap Yazarını Giriniz"
                      value={kitapYazariVal}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Video Çözüm Link</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setUpdatedFormData({ ...updatedFormData, cözümVideoLink: e.target.value }) + setCözümVideoLinkVal(e.target.value)
                      }
                      type="name"
                      placeholder="Video Çözüm Linki Giriniz"
                      value={cözümVideoLinkVal}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Kitap Demo</Form.Label>
                    <Form.Control
                      onChange={handlePdf}
                      type="file"
                      name="demoKitapPdf"
                      placeholder="Kurum Fotoğrafı Ekleyiniz"
                    />
                    {/* Eğer demokitapPdf.ucUrl'si bizim sabit belirlediğimiz url ise yani yoksa mevcut değil yazısı yazacak, varsa var yazacak */}
                    {props.updateBilgiler !== undefined ?

                      <div className="d-flex justify-content-center">
                        {props.updateBilgiler.demoKitapPdf.ucUrl === "https://drive.google.com/uc?id=1mOQlkAD_7B2jsTkq0NkgnlveuKrhEtWz" ?
                          <p style={{ color: "red" }}>Demo Pdf Mevcut Değil</p>
                          :
                          <p style={{ color: "#3cff3c" }}>Demo Pdf Mevcut</p>
                        }
                      </div>

                      : null
                    }
                  </Form.Group>


                  <Form.Group className="d-grid">


                    <Button variant="primary" type="submit" className="mt-4 w-100">
                      Güncelle
                    </Button>

                    <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                      Vazgeç
                    </Button>

                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>


        </Modal.Body>


      </Modal>
    </>
  );






};
export default UpdateScreen;
