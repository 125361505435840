import React, { useState, useEffect,useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { listeler } from "../axios";

// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { fetchUsers } from "../axios/index";

import CompanyCard from "./Card/CompanyCard";
import CompanyCardFilter from "./Card/CompanyCardFilter";
// import CompanyInfos from "./Card/CompanyInfos";

import { HelmetProvider, Helmet } from "react-helmet-async";



function KK_Add() {

  const [tumLıste, setTumLıste] = useState([]);
  const isInitialMount = useRef(true);

  useEffect(() => {
    listeler()
      .then((res) => {
        setTumLıste(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const [secilenYayinlar, setSecilenYayinlar] = useState([]);
  const [secilenSiniflar, setSecilenSiniflar] = React.useState([]);
  const [secilenBranslar, setSecilenBranslar] = React.useState([]);
  const [secilenKitapTurler, setSecilenKitapTurler] = React.useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  // Tabloda ara'nın içindeki yazıları sildikçe bu fonksiyon çalışır.
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
      const params = new URLSearchParams(location.search);
      params.delete("ara");
      navigate(`/kitaplar?${params.toString()}`);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  // Link üzerinden gelen search parametrelerini oku ve state'e aktar
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const yayinlarParam = params.getAll('yayin') || [];
    const siniflarParam = params.getAll('sinif') || [];
    const branslarParam = params.getAll('brans') || [];
    const kitapTurlerParam = params.getAll('kitapTur') || [];
    const searchParam = params.get("ara") || "";

    // State'den aldığımız URL parametrelerini uygun şekilde ayrıştırıp değişkenlere tanımlıyoruz.
    setSecilenYayinlar(yayinlarParam.map(yayin => decodeURIComponent(yayin))); // decode here
    setSecilenSiniflar(siniflarParam.map(sinif => decodeURIComponent(sinif))); // decode here
    setSecilenBranslar(branslarParam.map(brans => decodeURIComponent(brans))); // decode here
    setSecilenKitapTurler(kitapTurlerParam.map(kitapTur => decodeURIComponent(kitapTur))); // decode here


    if(isInitialMount.current){
      setFilterText(decodeURIComponent(searchParam));
      isInitialMount.current = false;
    }

  }, [location.pathname, location.search]);




  const updateURL = () => {
    const params = new URLSearchParams();

    // Linkteki dizi elemanlarını virgülle birleştir ve URL parametrelerine ekle
    secilenYayinlar.forEach(yayin => {
      params.append('yayin', yayin);
    });
    secilenSiniflar.forEach(sinif => {
      params.append('sinif', sinif);
    });
    secilenBranslar.forEach(brans => {
      params.append('brans', brans);
    });
    secilenKitapTurler.forEach(kitapTur => {
      params.append('kitapTur', kitapTur);
    });
    params.append("ara",filterText);

    // Yeni web linkini oluştur
    const newPath = `/kitaplar?${params.toString()}`;
    // Yeni web linkine yönlendir
    if (secilenYayinlar.length > 0 || secilenSiniflar.length > 0 || secilenBranslar.length > 0 || secilenKitapTurler.length > 0 || filterText !== "") {
      navigate(newPath);
    }
  };
  // Filtreler değiştiğinde URL'yi güncelle
  useEffect(() => {
    if (secilenYayinlar.length > 0 || secilenSiniflar.length > 0 || secilenBranslar.length > 0 || secilenKitapTurler.length > 0 || filterText !== "") {
      updateURL();
    }
  }, [secilenYayinlar, secilenSiniflar, secilenBranslar, secilenKitapTurler, filterText, navigate]);



  // Bu fonksiyon eğer hiçbir filtre yok ise /kitaplar sayfasına yönlendiriyor. setTimeOut kullanma sebebimiz, İlk olarak filtrelerin boş gelmesidir.
  // Bu fonksiyonu 50 milisaniye bekletiyoruz ki filtreler yüklensin.
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (secilenYayinlar.length === 0 && secilenSiniflar.length === 0 && secilenBranslar.length === 0 && secilenKitapTurler.length === 0 && filterText === "") {
        navigate("/kitaplar");
      }
    }, 50);

    // useEffect içinde setTimeout kullanıyorsanız, temizleme işlemi önemlidir.
    return () => clearTimeout(timeoutId);

  }, [secilenYayinlar, secilenSiniflar, secilenBranslar, secilenKitapTurler,filterText, navigate]);

const [kitapKapakFotoMeta,setKitapKapakFotoMeta] = useState(null);

  const [kitaplarInfo, setkitaplarInfo] = useState([]);

  useEffect(() => {
    if (kitaplarInfo.length > 0 && kitaplarInfo[0].kitapKapakFoto) {
      setKitapKapakFotoMeta(kitaplarInfo[0].kitapKapakFoto.ucUrl);
    }
  }, [kitaplarInfo]);


  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>Eğitim İçerik Örnek Kitap</title>
          <meta name="title" content="Eğitim İçerik Örnek Kitap" />
          <meta name="description" content="Eğitim içerik üzerinden örnek kitap inceleme platformu" />
          <meta property="og:image" content={kitapKapakFotoMeta} crossOrigin="anonymous"/>

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Eğitim İçerik Örnek Kitap" />
          <meta property="og:description" content="Eğitim içerik üzerinden örnek kitap inceleme platformu" />
          <meta property="og:image" content={kitapKapakFotoMeta} crossOrigin="anonymous"/>

          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:title" content="Eğitim İçerik Örnek Kitap" />
          <meta property="twitter:description" content="Eğitim içerik üzerinden örnek kitap inceleme platformu" />
          <meta property="twitter:image" content="https://r.resimlink.com/lTV3aS-J5.jpg"/>
        </Helmet>
      </HelmetProvider>

      <div className="container kurumListesi">
        <CompanyCardFilter
           onFilter={e => {
            const newText = e.target.value.toLocaleUpperCase('tr-TR');
          setFilterText(newText)
          }}
          filterText={filterText}
          handleClear={handleClear}
          setSecilenYayinlar={setSecilenYayinlar}
          setSecilenSiniflar={setSecilenSiniflar}
          setSecilenBranslar={setSecilenBranslar}
          setSecilenKitapTurler={setSecilenKitapTurler}
          secilenYayinlar={secilenYayinlar}
          secilenSiniflar={secilenSiniflar}
          secilenBranslar={secilenBranslar}
          secilenKitapTurler={secilenKitapTurler}
          setFilterText={setFilterText}
          tumLıste={tumLıste}
        />

        {/* <CompanyInfos
          kitaplarInfo={kitaplarInfo}
          /> */}

        <CompanyCard
          tumLıste={tumLıste}
          secilenYayinlar={secilenYayinlar}
          secilenSiniflar={secilenSiniflar}
          secilenBranslar={secilenBranslar}
          secilenKitapTurler={secilenKitapTurler}
          filterText={filterText}
          setkitaplarInfo={setkitaplarInfo}
          kitaplarInfo={kitaplarInfo}
        />

        <ToastContainer />
      </div>
    </>


  )


}

export default KK_Add;