import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Modal,Spinner } from "react-bootstrap";
import { setEkle } from "../../axios/index";

// MODAL CSS 
import "../css/Modal.css";
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';


const SetAddScreen = (props) => {

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [bilgilerSet,setBilgilerSet] = useState({
    setAdi: "",
    setKapakFoto : [],
    setYonlendirme: "",
  });

  const [formData, setFormData] = useState({
    yayinAdi: "",
    bilgilerSet : bilgilerSet,
  });

  

  const formDataSıfırla = () => {
    setBilgilerSet({
      setAdi: "",
      setKapakFoto: null,
      setYonlendirme: "",
    });
    setFormData({
      yayinAdi: "",
      bilgilerSet: {
        setAdi: "",
        setKapakFoto: null,
        setYonlendirme: "",
      },
    });
    console.log("form sıfırlandı");
    console.log(formData);
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    setBilgilerSet({...bilgilerSet,setKapakFoto:file})
    console.log(file)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // setFormData işlemi burada tamamlanıyor
    setFormData({ ...formData, bilgilerSet });
    setIsFormSubmitted(true);
  }

  useEffect(() => {
    if (isFormSubmitted) {
      const toastId = toast.loading("Set yükleniyor...");
      setEkle(formData)
        .then((res) => {
          handleClose();
          toast.update(toastId, { render: 'Set başarıyla yüklendi ! 👌', type: toast.TYPE.SUCCESS, isLoading: false });
          console.log(formData);
          formDataSıfırla();
          setIsFormSubmitted(false);
          console.log(formData);
          console.log(res.data);
        })
        .catch((err) => {
          toast.update(toastId, { render: 'Set yüklenemedi ! 🤯', type: toast.TYPE.ERROR, isLoading: false });
          toast.error(err.response.data.message);
          setIsFormSubmitted(false);
          console.log(formData);
        });
    } else {
      toast.dismiss(); // Toast'ı gizle
    }
  }, [isFormSubmitted]);




  // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ



  return (
    <>
        <Button style={{ fontSize: "18px", fontWeight: "500" }} onClick={handleShow} variant="primary">
          <FontAwesomeIcon className="me-3" icon={faPlus} size="1x" />
          Yeni Set Oluştur
        </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Set Ekleme</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={9}>
              <Form encType="multipart/form-data" onSubmit={handleSubmit}>

                
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Yayın Adı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setFormData({ ...formData, yayinAdi: e.target.value.toLocaleUpperCase('tr-TR') })
                      }
                      type="name"
                      placeholder="Yayın Adını Giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Set Adı</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setBilgilerSet({ ...bilgilerSet, setAdi: e.target.value })
                      }
                      type="name"
                      placeholder="Set Adını Giriniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Set Kapak Fotoğraf</Form.Label>
                    <Form.Control
                      onChange={handleImage}
                      type="file"
                      name="setKapakFoto"
                      placeholder="Set Fotoğrafı Ekleyiniz"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Set Yönlendirme</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setBilgilerSet({ ...bilgilerSet, setYonlendirme: e.target.value })
                      }
                      type="name"
                      placeholder="Yönlendirme Linkini Giriniz"
                    />
                  </Form.Group>
              
                  <Form.Group className="d-grid">

                    <Button variant="primary" type="submit" className="mt-4 w-100">
                      Kayıt Ekle
                    </Button>

                    <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                      Vazgeç
                    </Button>

                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>


        </Modal.Body>


      </Modal>
    </>
  );


};
export default SetAddScreen;
