//React paketler
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

//React yazı paketi
import TypeWriter from "../Components/TypeWriter";
// Css importlar
import "./css/HomeScreen.css";

// Fotograflar import
import aciTasarim from "./img/aciegitimkurumlarikolejklasik.jpg";
import aciKolejGiris from "./img/acikolejgiris.jpg";
import bookicon from "./img/bookicon.png";
import videoplayicon from "./img/videoplayicon.png";
import smartboardicon from "./img/smartboardicon.png";
import kitaparkaplanfoto from "./img/kitaparkaplanfoto.png";
import donenkitap from "./img/donenkitap.mp4"

// Iconlar
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPhone, faMailBulk, faMap, faAnglesDown } from '@fortawesome/free-solid-svg-icons';

// axios import
import { setListele } from "../axios";

// import ReactGA from "react-ga";


// function initialGA() {
//   ReactGA.initialize("G-28S9VR2RMS");
//   ReactGA.pageview(window.location);
// }


const HomeScreen = ({ user }) => {

  const [setListe, setSetListe] = useState([]);
  const [kurumInfo, setKurumInfo] = useState(null);
  const [kurumAraTıklandimi, setKurumAraTıklandimi] = useState(false);

  const navigate = useNavigate();
  // initialGA();

  useEffect(() => {
    setListele()
      .then((res) => {
        setSetListe(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  return (
    <div className="container-fluid homeScreenContainer">
      <div className="homeScreenContainerBackground">

        <div className="row homeScreenCarousel">

          <div className="carouselAlan col-md-12">


            <div className="homeScreenPhotoDiv">
              <video src={donenkitap} alt="Kitap Video" autoPlay loop muted />
            </div>
            <TypeWriter className="typeWriter"></TypeWriter>
          </div>

        </div>

        <div className="homePageContentArea d-flex justify-content-center align-items-center">
          <div className="cardAlann">

            <Link className="cardAlanLink" to="https://ornekkitap.egitimdijital.com/kitaplar">
              <div className="card cardAlan-cardd bg-none">
                <img alt="video çözüm fotoğraf" src={bookicon} width={"80px"}></img>
                <p>Kitap Örnekler</p>
              </div>
            </Link>

            <Link target="blank" className="cardAlanLink" to="https://cozum.egitimicerik.com">
              <div className="card cardAlan-cardd bg-none">
                <img alt="video çözüm fotoğraf" src={videoplayicon} width={"80px"}></img>
                <p>Video Çözümler</p>
              </div>
            </Link>

            <Link target="blank" className="cardAlanLink" to="https://egitimicerik.com">
              <div className="card cardAlan-cardd bg-none">
                <img alt="video çözüm fotoğraf" src={smartboardicon} width={"80px"}></img>
                <p>Akıllı Tahta Uygulamaları</p>
              </div>
            </Link>

          </div>
          {/* <FontAwesomeIcon size="4x" color="white" style={{marginRight:"40px"}} icon={faAnglesDown}></FontAwesomeIcon>
          <h1 className="homePageContentAreaH1" style={{fontSize:"60px", color:"white",fontFamily:"fantasy"}}>SET LİSTESİ</h1>
          <FontAwesomeIcon size="4x" color="white" style={{marginLeft:"40px"}} icon={faAnglesDown}></FontAwesomeIcon> */}
        </div>


        <div className="homepageKurumYonlendirmeAlanRow">
          <div className="homepageKurumYonlendirmeAlan container">
            {setListe.map((yayinlar) => {
              return (
                <div className="homepageSetlerGenelAlan" key={yayinlar._id}>
                  <div className="homepageYayinAlan">
                    <h2>{yayinlar.yayinAdi}</h2>
                  </div>
                  <div className="homePageSetAlan">
                    {yayinlar.bilgilerSet.map((setler) => {
                      return (
                        <div className="homePageSetCard" key={setler._id}>
                          <img alt="Setin Kapak Fotoğrafı" src={setler.setKapakFoto.url} onClick={() => navigate(setler.setYonlendirme)} />
                          <h3 style={{ color: "black", textAlign: "center", marginBottom: "0px" }}>{setler.setAdi}</h3>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

{/* <img src="https://storage.googleapis.com/ornekkitap-bucket/Kitap%20Demo%20Pdfler/kitapbulunamad%C4%B1.png"/> */}

        <div className={kurumAraTıklandimi === false ? "row kurumCardRow d-none" : "row mt-5 kurumCardRow d-block"}>
          <div className="card kurumCardArea">

            <div className="kurumPhotoArea">
              <img src={kurumInfo !== null && kurumInfo.kurumFoto && kurumInfo.kurumFoto.url ? kurumInfo.kurumFoto.url : aciTasarim} alt="Kurum Fotoğraf" />
            </div>


            <div className="kurumInfoArea">
              <div className="kurumInfoBaslik">
                <h3 className="text-center">Kurum Bilgileri</h3>
              </div>
              <div className="kurumInfo">
                <p> <FontAwesomeIcon icon={faBuilding} className="me-3" />{kurumInfo === null ? null : kurumInfo.kurumAdı}</p>
                <p> <FontAwesomeIcon icon={faPhone} className="me-3" />{kurumInfo === null ? null : kurumInfo.telefonNo}</p>
                <p> <FontAwesomeIcon icon={faMailBulk} className="me-3" />{kurumInfo === null ? null : kurumInfo.email}</p>
                <p> <FontAwesomeIcon icon={faMap} className="me-3" />{kurumInfo === null ? null : kurumInfo.kurumAdres}</p>
              </div>

            </div>

          </div>
        </div>

        {/* <Link style={{textDecoration:"none"}} to="/kurumlar">
        <div className="homepageKurumListesiYonlendirme">
          <p>TÜM KURUMLARI GÖRMEK İÇİN TIKLAYINIZ</p>
        </div>
        </Link> */}

        {/* <Link style={{textDecoration:"none"}} to="/kurumlar">
        <div className="homepageKurumListesiYonlendirme">

        </div>
        </Link> */}

      </div>

      {user?.email}
    </div>
  )
};

export default HomeScreen;
