import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { setYayinGuncelle } from "../../axios/index";

// MODAL CSS 
import "../css/Modal.css";
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons';


const SetYayinUpdate = (props) => {

    //MODAL DEĞİŞKENLERİ
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [yayinAdiVal, setYayinAdiVal] = useState();
    const [yayinId, setYayinId] = useState();


    const handleShowAndId = (yayinId,yayinAdiVal) => {
        handleShow();
        console.log(`yayinId: ${yayinId}`)
        console.log(`yayın Adı: ${yayinAdiVal}`)
        setYayinId(yayinId)
        setYayinAdiVal(yayinAdiVal)
    };

    const [updatedFormData, setUpdatedFormData] = useState({
        yayinAdi : ""
    });

    const updatedFormDataSıfırla = () => {
        setUpdatedFormData({
            yayinAdi : ""
        });
        console.log("form sıfırlandı");
        console.log(updatedFormData);
    };

    console.log(props)

    // BU SAYFADA updatedFormData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ


    return (
        <>

            <FontAwesomeIcon
                style={{ fontSize: "20px", color: "black" }}
                className="me-3"
                icon={faCog}
                size="1x"
                onClick={() => {
                    handleShowAndId(props.setler._id,props.setler.yayinAdi);
                }}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Ekleme</Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12} md={9}>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        console.log(yayinId, updatedFormData)
                                        setYayinGuncelle(yayinId, updatedFormData)
                                            .then((res) => {
                                                console.log(res);
                                                // İşlem tamamlandığında modalı kapat
                                                handleClose();
                                                toast.success("Set Güncelleme Başarılı!");
                                                updatedFormDataSıfırla();
                                            })
                                            .catch((err) => {
                                                console.error("İstek hatası: ", err);
                                                toast.error(err.response ? err.response.data.message : "Bir hata oluştu");
                                            });
                                    }}
                                >

                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>Yayın Adı</Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                setUpdatedFormData({ ...updatedFormData, yayinAdi: e.target.value.toLocaleUpperCase('tr-TR') }) +
                                                setYayinAdiVal(e.target.value)
                                            }
                                            type="name"
                                            placeholder="Set Adını Giriniz"
                                            value={yayinAdiVal}

                                        />
                                    </Form.Group>

                                    <Form.Group className="d-grid">

                                        <Button variant="primary" type="submit" className="mt-4 w-100">
                                            Kayıt Ekle
                                        </Button>

                                        <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                                            Vazgeç
                                        </Button>

                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Container>


                </Modal.Body>


            </Modal>
        </>
    );


};
export default SetYayinUpdate;
