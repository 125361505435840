import React, { useEffect, useState } from "react";
import {Form, Button,Modal } from "react-bootstrap";


// MODAL CSS 
import "./css/Modal.css";

// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons';


const FeedbackDetailScreen = (props) => {

    const [adVal, setAdVal] = useState('');
    const [epostaVal, setEpostaVal] = useState('');
    const [telefonNoVal, setTelefonNoVal] = useState('');
    const [mesajTurVal, setMesajTurVal] = useState('');
    const [mesajSaatVal, setMesajSaatVal] = useState('');
    const [mesajVal, setMesajVal] = useState('');

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);




  useEffect(()=>{ 
    if(props.updateBilgiler !== undefined){
        setAdVal(props.updateBilgiler.sikayetYapanınIsım)
        setEpostaVal(props.updateBilgiler.sikayetYapanınEposta)
        setTelefonNoVal(props.updateBilgiler.sikayetYapanınTelefonNo)
        setMesajTurVal(props.updateBilgiler.sikayetTuru)
        setMesajSaatVal(props.updateBilgiler.sikayetTarihi)
        setMesajVal(props.updateBilgiler.sikayetText)

    }
    
},[props.updateBilgiler])

    // console.log(props.updateBilgiler)

  return (
    <>
      <FontAwesomeIcon style={{fontSize:"20px"}}  onClick={handleShow} className="me-3" icon={faCog} size="1x" />

      <Modal show={show} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Mesaj Detay</Modal.Title>
        </Modal.Header>

        <Modal.Body>

        <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Ad Soyad</Form.Label>
              <Form.Control 
                readOnly={true}
                type="name"
                value={adVal}
              />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>E-posta</Form.Label>
              <Form.Control 
                readOnly={true}
                type="name"
                value={epostaVal}
              />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Telefon No</Form.Label>
              <Form.Control 
                readOnly={true}
                type="name"
                value={telefonNoVal}
              />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Mesaj Saati</Form.Label>
              <Form.Control 
                readOnly={true}
                type="name"
                value={mesajSaatVal}
              />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Mesaj Türü</Form.Label>
              <Form.Control 
                readOnly={true}
                type="name"
                value={mesajTurVal}
              />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Mesaj</Form.Label>
              <Form.Control 
                readOnly={true}
                as="textarea"
                rows={4}
                value={mesajVal}
              />
        </Form.Group>

        </Modal.Body>


        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>

    </>
  );




  
    
};
export default FeedbackDetailScreen;
