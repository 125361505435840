import React  from "react";
import styled from "styled-components";

// !!! İCONLAR !!!

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const FilterComponent = ({ filterText, onFilter, onClear,setSecilenMesajTur }) => {

  

  return(

  <div style={{ width: "100%" }}>


    <div className="search-and-add-area">
      <div className="searchFilterAlan">
        <FontAwesomeIcon className="headerSearchIcon" icon={faSearch}></FontAwesomeIcon>
        
        <Input
          id="search"
          type="text"
          placeholder="Tabloda Ara..."
          className="headerFilter"
          value={filterText}
          onChange={onFilter}
        />
      
        <ClearButton className="headerClear" onClick={onClear}><FontAwesomeIcon icon={faX} /></ClearButton>

      </div>


      <div className="headerOptionAlan mt-3">
      <select
        defaultValue={"mesajTur"}
        className="headerSelect "
        onChange={(e)=>{
           e.target.value === "mesajTur" ? setSecilenMesajTur("") : setSecilenMesajTur(e.target.value);
        }}
      >
        <option value="mesajTur">Mesaj Türü Seç</option>
        <option value="istek">İstek</option>
        <option value="oneri">Öneri</option>
        <option value="sikayet">Şikayet</option>
        <option value="soru">Soru</option>
      </select>

      
    </div>

    </div>

  </div>

)};

export default FilterComponent;
