import React, { useState, useEffect } from "react";
import { listeler } from "../axios";

// !!! DATATABLE İMPORTLAR !!!

import Table from "./Table/DataTable";
import "./Table/styles.css";

// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function KK_Add() {


  const updateClickHandler = (updateAlan) => {
    console.log("Güncelle", updateAlan); 
  }
  const deleteClickHandler = deleteAlan => console.log("Sil", deleteAlan);


  const [tumLıste, setTumLıste] = useState([]);

  useEffect(() => {
    listeler()
      .then((res) => {
        setTumLıste(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

return(
  <>
  <Table data={tumLıste}  updateClickHandler={updateClickHandler} deleteClickHandler={deleteClickHandler} />
  <ToastContainer/>
  </>


)


}

export default KK_Add;