import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { setElemanUpdate } from "../../axios/index";

// MODAL CSS 
import "../css/Modal.css";
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons';


const SetJustsetAddScreen = (props) => {

    //MODAL DEĞİŞKENLERİ
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [setAdiVal, setSetAdiVal] = useState();
    const [setYonlendirmeVal, setSetYonlendirmeVal] = useState();

    const [yayinId, setYayinId] = useState();
    const [setId, setSetId] = useState();

    const handleShowAndId = (yayinId, setId) => {
        handleShow();
        console.log(`yayinId: ${yayinId}`)
        console.log(`setId: ${setId}`)
        setYayinId(yayinId)
        setSetId(setId)
    };

    const [updatedFormData, setUpdatedFormData] = useState({
        setAdi: "",
        setKapakFoto: [],
        setYonlendirme: "",
    });

    const updatedFormDataSıfırla = () => {
        setUpdatedFormData({
            setAdi: "",
            setKapakFoto: null,
            setYonlendirme: "",
        });
        console.log("form sıfırlandı");
        console.log(updatedFormData);
    };

    const handleImage = (e) => {
        const file = e.target.files[0];
        setUpdatedFormData({ ...updatedFormData, setKapakFoto: file })
        console.log(file)
    }

    console.log(props)

    // BU SAYFADA updatedFormData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ


    return (
        <>

            <FontAwesomeIcon
                style={{ fontSize: "20px", color: "black" }}
                className="me-3"
                icon={faCog}
                size="1x"
                onClick={() => {
                    handleShowAndId(props.setler._id, props.bilgilersetmap._id);
                    setSetAdiVal(props.bilgilersetmap.setAdi);
                    setSetYonlendirmeVal(props.bilgilersetmap.setYonlendirme);
                }}

            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Ekleme</Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12} md={9}>
                                <Form
                                    encType="multipart/form-data"
                                    onSubmit={(e) => {
                                        e.preventDefault();

                                        const toastId = toast.loading("Set güncelleniyor...");

                                        console.log(yayinId, setId, updatedFormData)
                                        setElemanUpdate(yayinId, setId, updatedFormData)
                                            .then((res) => {
                                                console.log(res);
                                                // İşlem tamamlandığında modalı kapat
                                                handleClose();
                                                toast.update(toastId, { render: 'Set başarıyla güncellendi ! 👌', type: toast.TYPE.SUCCESS, isLoading: false });
                                                updatedFormDataSıfırla();
                                                toast.dismiss();
                                            })
                                            .catch((err) => {
                                                console.error("İstek hatası: ", err);
                                                toast.update(toastId, { render: 'Set güncelleme başarısız ! 🤯', type: toast.TYPE.ERROR, isLoading: false });
                                                toast.dismiss();
                                            });
                                    }}
                                >

                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>Set Adı</Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                setUpdatedFormData({ ...updatedFormData, setAdi: e.target.value }) +
                                                setSetAdiVal(e.target.value)
                                            }
                                            type="name"
                                            placeholder="Set Adını Giriniz"
                                            value={setAdiVal}

                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>Set Kapak Fotoğraf</Form.Label>
                                        <Form.Control
                                            onChange={handleImage}
                                            type="file"
                                            name="setKapakFoto"
                                            placeholder="Set Fotoğrafı Ekleyiniz"
                                        />
                                        {props.bilgilersetmap !== undefined ?
                                            <div className="d-flex justify-content-center">
                                                <img crossOrigin="anonymous" alt="Set Fotoğrafı" style={{ width: "100%", height: "300px", marginTop: "20px" }} src={props.bilgilersetmap.setKapakFoto ? props.bilgilersetmap.setKapakFoto.ucUrl : "https://drive.google.com/uc?id=1eaMYbXBSNYonurQEKx9V0pDPV4NHIBh9"} />
                                            </div>
                                            : null}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>Set Yönlendirme</Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                setUpdatedFormData({ ...updatedFormData, setYonlendirme: e.target.value }) +
                                                setSetYonlendirmeVal(e.target.value)
                                            }
                                            type="name"
                                            placeholder="Yönlendirme Linkini Giriniz"
                                            value={setYonlendirmeVal}
                                        />
                                    </Form.Group>

                                    <Form.Group className="d-grid">

                                        <Button variant="primary" type="submit" className="mt-4 w-100">
                                            Kayıt Ekle
                                        </Button>

                                        <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                                            Vazgeç
                                        </Button>

                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Container>


                </Modal.Body>


            </Modal>
        </>
    );


};
export default SetJustsetAddScreen;
