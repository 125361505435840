import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../axios/index";
import acilogo from "./img/aciegitimkurumlarilogo.png";
import "./css/LoginScreen.css";

const LoginScreen = ({ setUser }) => {
  const navigate = useNavigate();

  const formemail = useRef();
  const formpassword = useRef();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  return (

    <div className="loginScreen text-center d-flex justify-content-center align-items-center">

        <form className="form-signin w-50" onSubmit={(e)=>{
        e.preventDefault();
        login(formData)
        .then((res)=>{      
          console.log(res);
          localStorage.setItem("user",JSON.stringify(res.data.user))
          setUser(res.data.user)
          console.log("dol")
          
          navigate("/aciadmin")
          
        })
        .catch((err)=>{
          console.log(err.response.data);

         
          if(err.response.data.message === "Böyle bir kullanıcı bulunmamaktadır."){
            console.log("evet")
            formemail.current.value = "";
            formpassword.current.value = "";
            formemail.current.style.borderColor = "red";
            formemail.current.placeholder = err.response.data.message;
          }
          else if(err.response.data.message === "Hatalı Şifre"){
            console.log(formpassword)
            formpassword.current.value = "";
            formpassword.current.placeholder = err.response.data.message;
            formpassword.current.style.borderColor = "red";
            formemail.current.style.borderColor = "";
          }

        })
        
      }}>

          <img src={acilogo}className="mb-4" alt="acilogo" height="72"/>
          <h1 className="h3 mb-3 font-weight-normal">Giriş Yap</h1>
    
          <div className="email_pass_form">

          <div className="form-email">
            <input ref={formemail} onChange={(e)=>setFormData({...formData, email:e.target.value})} type="email" id="inputEmail" className="form-control" placeholder="E-Posta" required="" autoFocus=""/>
            <i className="fa-solid fa-envelope email-icon"></i>
          </div>
          

          <div className="form-pass">
          <input ref={formpassword} onChange={(e)=>setFormData({...formData, password:e.target.value})} type="password" id="inputPassword" className="form-control" placeholder="Parola" required=""/>
          <i className="fa-sharp fa-solid fa-key passkey-icon"></i>
          </div>
          
          </div>
        

          <button className="btn btn-lg btn-primary btn-block w-50 mt-4" type="submit">Giriş Yap</button>
          <p className="mt-5 mb-3 text-muted">© 2022-2023</p>
        </form>

    </div>

  );
};

export default LoginScreen;
