// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .footerArea{
    position: absolute;
    bottom: 0;
    width: 100%;
} */
@media (max-width: 400px) {
    .footerAciLogo{
        max-width: 150px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/musteriekran/Screens/css/Footer.css"],"names":[],"mappings":"AAAA;;;;GAIG;AACH;IACI;QACI,gBAAgB;IACpB;EACF","sourcesContent":["/* .footerArea{\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n} */\n@media (max-width: 400px) {\n    .footerAciLogo{\n        max-width: 150px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
