// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: sans-serif;
    /* text-align: center; */
  }
  
  .rdt_Table {
    margin-bottom: 1rem;
  }
  .rdt_TableRow {
    background-color: grey;
  }
  .rdt_TableCol {
    color: black;
    font-weight: bolder;
  }
  .rdt_TableCol_Sortable {
  }
  .rdt_TableCell {
  }
  .rdt_TableHeader {
    background-color: transparent;
  }
  .rdt_TableFooter {
  }
  .rdt_TableHead {
  }
  .rdt_TableHeadRow {
    background-color: green;
  }
  .rdt_TableBody {
  }
  .rdt_ExpanderRow {
  }
  `, "",{"version":3,"sources":["webpack://./src/adminekran/Screens/Table/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wBAAwB;EAC1B;;EAEA;IACE,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,mBAAmB;EACrB;EACA;EACA;EACA;EACA;EACA;IACE,6BAA6B;EAC/B;EACA;EACA;EACA;EACA;EACA;IACE,uBAAuB;EACzB;EACA;EACA;EACA;EACA","sourcesContent":[".App {\n    font-family: sans-serif;\n    /* text-align: center; */\n  }\n  \n  .rdt_Table {\n    margin-bottom: 1rem;\n  }\n  .rdt_TableRow {\n    background-color: grey;\n  }\n  .rdt_TableCol {\n    color: black;\n    font-weight: bolder;\n  }\n  .rdt_TableCol_Sortable {\n  }\n  .rdt_TableCell {\n  }\n  .rdt_TableHeader {\n    background-color: transparent;\n  }\n  .rdt_TableFooter {\n  }\n  .rdt_TableHead {\n  }\n  .rdt_TableHeadRow {\n    background-color: green;\n  }\n  .rdt_TableBody {\n  }\n  .rdt_ExpanderRow {\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
