import React, { useState,useEffect,useRef } from "react";
import styled from "styled-components";

// servisler
import yayinlar from "../service/yayinlar.json"
import siniflar from "../service/sınıflar.json"
import branslar from "../service/branslar.json"
import kitapTurler from "../service/kitapTur.json"
// !!! İCONLAR !!!

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
// !!! İCONLAR !!!

import "./CompanyCardFilter.css";

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function CompanyCardFilter({ setSecilenYayinlar, setSecilenSiniflar, setSecilenBranslar, setSecilenKitapTurler, secilenYayinlar, secilenSiniflar, secilenBranslar, secilenKitapTurler, tumLıste, handleClear, onFilter, filterText,setFilterText }) {


  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [expandedSinif, setExpandedSinif] = useState(false);
  const [expandedBrans, setExpandedBrans] = useState(false);
  const [expandedKitapTur, setExpandedKitapTur] = useState(false);

  const checkboxesMusteri = document.getElementById("checkboxesMusteri");
  const checkboxesMusteriSinif = document.getElementById("checkboxesMusteriSinif");
  const checkboxesMusteriBrans = document.getElementById("checkboxesMusteriBrans");
  const checkboxesMusteriKitapTur = document.getElementById("checkboxesMusteriKitapTur");

  const checkboxesMusteriRef = useRef(null);
const checkboxesMusteriSinifRef = useRef(null);
const checkboxesMusteriBransRef = useRef(null);
const checkboxesMusteriKitapTurRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.selectBox')) {
        if(checkboxesMusteriRef.current !== null){
          checkboxesMusteriRef.current.style.display = "none";
          setExpanded(false);
        }
      }
      if (!event.target.closest('.selectBoxSinif')) {
        if(checkboxesMusteriSinifRef.current !== null){
          checkboxesMusteriSinifRef.current.style.display = "none";
          setExpandedSinif(false);
        }
      }
      if (!event.target.closest('.selectBoxBrans')) {
        if(checkboxesMusteriBransRef.current !== null){
          checkboxesMusteriBransRef.current.style.display = "none";
          setExpandedBrans(false);
        }
      }
      if (!event.target.closest('.selectBoxKitapTur')) {
        if(checkboxesMusteriKitapTurRef.current !== null){
          checkboxesMusteriKitapTurRef.current.style.display = "none";
          setExpandedKitapTur(false);
        }
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  function showcheckboxesMusteri() {
    toggleCheckboxes("checkboxesMusteri", expanded, setExpanded);
  }

  function showcheckboxesMusteriSinif() {
    toggleCheckboxes("checkboxesMusteriSinif", expandedSinif, setExpandedSinif);
  }

  function showcheckboxesMusteriBrans() {
    toggleCheckboxes("checkboxesMusteriBrans", expandedBrans, setExpandedBrans);
  }

  function showcheckboxesMusteriKitapTur() {
    toggleCheckboxes("checkboxesMusteriKitapTur", expandedKitapTur, setExpandedKitapTur);
  }

  function toggleCheckboxes(checkboxId, expandedState, setExpandedState) {
    const checkboxes = document.getElementById(checkboxId);
    if (!expandedState) {
      closeAllCheckboxes();
      checkboxes.style.display = "flex";
      checkboxes.style.flexDirection = "column";
      setExpandedState(true);
    } else {
      checkboxes.style.display = "none";
      setExpandedState(false);
    }
  }

  function closeAllCheckboxes() {
    checkboxesMusteri.style.display = "none";
    checkboxesMusteriSinif.style.display = "none";
    checkboxesMusteriBrans.style.display = "none";
    checkboxesMusteriKitapTur.style.display = "none";

    setExpanded(false);
    setExpandedSinif(false);
    setExpandedBrans(false);
    setExpandedKitapTur(false);
  }

  const handleCheckboxChangeYayin = (event, yayinAdi) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSecilenYayinlar([...secilenYayinlar, yayinAdi]); // Yeni yayını ekleyin
    } else {
      setSecilenYayinlar(secilenYayinlar.filter((yayin) => yayin !== yayinAdi)); // Yayını çıkarın
    }
  };

  const handleCheckboxChangeSinif = (event, sinifAdi) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSecilenSiniflar([...secilenSiniflar, sinifAdi]); // Yeni yayını ekleyin
    } else {
      setSecilenSiniflar(secilenSiniflar.filter((sinif) => sinif !== sinifAdi)); // Yayını çıkarın
    }
  };

  const handleCheckboxChangeBrans = (event, brans) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSecilenBranslar([...secilenBranslar, brans]); // Yeni yayını ekleyin
    } else {
      setSecilenBranslar(secilenBranslar.filter((branss) => branss !== brans)); // Yayını çıkarın
    }
  };

  const handleCheckboxChangeKitapTur = (event, kitapTur) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSecilenKitapTurler([...secilenKitapTurler, kitapTur]); // Yeni yayını ekleyin
    } else {
      setSecilenKitapTurler(secilenKitapTurler.filter((kitapTurr) => kitapTurr !== kitapTur)); // Yayını çıkarın
    }
  };

  const filterReset = (() => {
    setSecilenYayinlar([]);
    setSecilenSiniflar([]);
    setSecilenBranslar([]);
    setSecilenKitapTurler([]);
    setFilterText("");
    navigate("/kitaplar");

    const checkboxesMusteri = document.querySelectorAll('input[type="checkbox"]');
    checkboxesMusteri.forEach((checkbox) => {
      checkbox.checked = false;
    });

  })

  useEffect(() => {
    checkboxesMusteriRef.current = document.getElementById("checkboxesMusteri");
    checkboxesMusteriSinifRef.current = document.getElementById("checkboxesMusteriSinif");
    checkboxesMusteriBransRef.current = document.getElementById("checkboxesMusteriBrans");
    checkboxesMusteriKitapTurRef.current = document.getElementById("checkboxesMusteriKitapTur");
  }, []);

  return (
    <>
      <div className="companyCardFilter card p-4">

        <div style={{ width: "100%" }}>


          <div className="search-and-add-area">
            <div className="searchFilterAlan">
              <FontAwesomeIcon className="headerSearchIcon" icon={faSearch}></FontAwesomeIcon>
              <input
                id="search"
                type="text"
                placeholder="Tabloda Ara..."
                className="headerFilter"
                value={filterText}
                onChange={onFilter}
              />
              <ClearButton className="headerClear" onClick={handleClear}><FontAwesomeIcon icon={faX} /></ClearButton>

            </div>

            <div className="filterResetArea">
              <button className="filterResetButton" onClick={filterReset}>
                <p className="mb-0 filterRemoveText">Filtreleri Kaldır</p>
                <div className="filterRemoveLine"></div>
                <p className="mb-0 filterRemoveX"><FontAwesomeIcon icon={faX} fontSize={"18px"} /></p></button>
            </div>

          </div>


          <div className="selectAlanMusteri">


            <div className="selectBox headerSelectMusteri" onClick={showcheckboxesMusteri}  ref={checkboxesMusteriRef}>
              <select>
                <option>
                  {secilenYayinlar.length === 0
                    ? "Yayın Seç"
                    : `${secilenYayinlar.length} Yayın Filtresi Aktif`}
                </option>
              </select>
              <div className="overSelect"></div>

              <div id="checkboxesMusteri" className="selectDivMusteri">

                {yayinlar.map((e) => {
                  return (
                    <label htmlFor={e.yayinAdi} key={e.yayinAdi}>

                      <input
                        type="checkbox"
                        checked={secilenYayinlar.includes(e.yayinAdi)}
                        value={e.yayinAdi}
                        id={e.yayinAdi}
                        onChange={(event) => handleCheckboxChangeYayin(event, e.yayinAdi)} />{e.yayinAdi}</label>
                  );
                })}

              </div>

            </div>

            <div className="selectBoxSinif headerSelectMusteri" onClick={showcheckboxesMusteriSinif} ref={checkboxesMusteriSinifRef}>
              <select>
                <option>
                  {secilenSiniflar.length === 0
                    ? "Sınıf Seç"
                    : `${secilenSiniflar.length} Sınıf Filtresi Aktif`}
                </option>
              </select>
              <div className="overSelect"></div>

              <div id="checkboxesMusteriSinif" className="selectDivMusteri">

                {siniflar.map((e) => {
                  return (
                    <label htmlFor={e.sinif} key={e.sinif}>

                      <input
                        type="checkbox"
                        checked={secilenSiniflar.includes(e.sinif)}
                        value={e.sinif}
                        id={e.sinif}
                        onChange={(event) => handleCheckboxChangeSinif(event, e.sinif)} />{e.sinif}</label>
                  );
                })}

              </div>

            </div>

            <div className="selectBoxBrans headerSelectMusteri" onClick={showcheckboxesMusteriBrans} ref={checkboxesMusteriBransRef}>
              <select>
                <option>
                  {secilenBranslar.length === 0
                    ? "Branş Seç"
                    : `${secilenBranslar.length} Branş Filtresi Aktif`}
                </option>
              </select>
              <div className="overSelect"></div>

              <div id="checkboxesMusteriBrans" className="selectDivMusteri">

                {branslar.map((e) => {
                  return (
                    <label htmlFor={e.brans} key={e.brans}>

                      <input type="checkbox"
                        checked={secilenBranslar.includes(e.brans)}
                        value={e.brans}
                        id={e.brans}
                        onChange={(event) => handleCheckboxChangeBrans(event, e.brans)} />{e.brans}</label>
                  );
                })}

              </div>

            </div>

            <div className="selectBoxKitapTur headerSelectMusteri" onClick={showcheckboxesMusteriKitapTur} ref={checkboxesMusteriKitapTurRef}>
              <select>
                <option>
                  {secilenKitapTurler.length === 0
                    ? "Kitap Türü Seç"
                    : `${secilenKitapTurler.length} Kitap Türü Filtresi Aktif`}
                </option>
              </select>
              <div className="overSelect"></div>

              <div id="checkboxesMusteriKitapTur" className="selectDivMusteri">

                {kitapTurler.map((e) => {
                  return (
                    <label htmlFor={e.kitapTur} key={e.kitapTur} >

                      <input type="checkbox"
                        checked={secilenKitapTurler.includes(e.kitapTur)}
                        value={e.kitapTur}
                        id={e.kitapTur}
                        onChange={(event) => handleCheckboxChangeKitapTur(event, e.kitapTur)} />{e.kitapTur}</label>
                  );
                })}

              </div>

            </div>

          </div>

        </div>

      </div>
    </>


  )


}

export default CompanyCardFilter;