import React, { useMemo, useState } from "react";

// import Modal from "react-modal";

// !!! DATATABLE İMPORTLAR !!!

import DataTable from "react-data-table-component";
import FeedbackTableFilterComponent from "./FeedbackTableFilterComponent";
import FeedbackDetailScreen from "../FeedbackDetailScreen";

// MODAL CSS 
import "../css/Modal.css"
// BİLDİRİM İÇİN PAKET
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ




const Table = props => {
  
  const [secilenMesajTur, setSecilenMesajTur] = useState("");
  const [updateBilgiler, setUpdateBilgiler] = useState();

  const updateClickHandler = (updateAlan) => {
    console.log("Güncelle", updateAlan);
    setUpdateBilgiler(updateAlan)
  }

  const columns = [
    {
      name: "İsim",
      selector: row => row.sikayetYapanınIsım,
      sortable: true,
      grow: 1,
    },
    {
      name: "E-posta",
      selector: row => row.sikayetYapanınEposta,
      sortable: true,
      hide: "md",
      grow: 1
    },
    {
      name: "Telefon No",
      selector: row => row.sikayetYapanınTelefonNo,
      sortable: true,
      hide: "lg",
      grow: 0.5
    },
    {
      name: "Mesaj Tür",
      selector: row => row.sikayetTuru,
      sortable: true,
      hide: "lg",
      grow: 0.4
    },
    {
      name: "Mesaj Saat",
      selector: row => row.sikayetTarihi,
      sortable: true,
      hide: "lg",
      grow: 0.5
    },
    {
      name: "Mesaj",
      selector: row => row.sikayetText,
      sortable: true,
      maxWidth:"300px",
      grow: 1
    },
    {
      name: "İşlemler",
      button: true,
      cell: row =>
      (
        <>

          <div onClick={() => {
            updateClickHandler(row)
          }}>
            <FeedbackDetailScreen updateBilgiler={updateBilgiler}></FeedbackDetailScreen>
          </div>

        </>
      )
    }
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  

  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );

  
  const filterMethods = [
    (item => item.sikayetTuru.includes(secilenMesajTur)),
    
  ]

  console.log(props)

  const filteredArray = props.data.filter((item) => {
    for (let i = 0; i < filterMethods.length; i++) {
      if (!filterMethods[i](item)) {
        return false
      }
    }
    return true
  })

console.log(filterText)

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FeedbackTableFilterComponent
        onFilter={e => setFilterText(e.target.value.toLocaleUpperCase('tr-TR'))}
        onClear={handleClear}
        filterText={filterText}
        setSecilenMesajTur={setSecilenMesajTur}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="Kurum Listesi"
      columns={columns}
      data={filteredArray}
      defaultSortField="name"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      paginationPerPage={50}
      noDataComponent="Herhangi bir veriye ulaşılmadı"

      paginationComponentOptions={{
        rowsPerPageText: "Sayfa başına kayıt sayısı:"
      }}
    />
  );
};

export default Table;
