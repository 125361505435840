// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.openBoxDiv{
    position: relative;
}
.openUserBox{
    padding: 10px;
    width: 260px;
    height: 150px;
    position: absolute;
    top: 50px;
    left: -204px;
    background-color: #0d6efd;
    border-radius: 5px;
    z-index: 3;
}
.cikisyapButton{
    margin-left: 70px;
}`, "",{"version":3,"sources":["webpack://./src/admin/pages/global/css/Topbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".openBoxDiv{\n    position: relative;\n}\n.openUserBox{\n    padding: 10px;\n    width: 260px;\n    height: 150px;\n    position: absolute;\n    top: 50px;\n    left: -204px;\n    background-color: #0d6efd;\n    border-radius: 5px;\n    z-index: 3;\n}\n.cikisyapButton{\n    margin-left: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
