import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import acitasarım from "../img/aciegitimkurumlarikolejklasik.jpg";
// !!! İCONLAR !!!
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faBook, faGraduationCap, faBookOpen } from '@fortawesome/free-solid-svg-icons';

// CSS
import "./CompanyCard.css";

function CompanyCard({ tumLıste, secilenYayinlar, secilenSiniflar, secilenBranslar, secilenKitapTurler, filterText, setkitaplarInfo, kitaplarInfo }) {

  const navigate = useNavigate();

  const handleInceleClick = ((kitap) => {
    const gelenKitapId = kitap._id;

    navigate(`/kitapdetay/${gelenKitapId}`);
  })


  const filterMethods = [
    (item) => secilenYayinlar.length === 0 || secilenYayinlar.includes(item.yayinAdi),
    (item) => secilenSiniflar.length === 0 || secilenSiniflar.includes(item.kitapSinif),
    (item) => secilenBranslar.length === 0 || secilenBranslar.includes(item.kitapBrans),
    (item) => secilenKitapTurler.length === 0 || secilenKitapTurler.includes(item.kitapTur),
    (item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
  ]

  useEffect(() => {
    const filteredArray = tumLıste.filter((item) => {
      for (let i = 0; i < filterMethods.length; i++) {
        if (!filterMethods[i](item)) {
          return false
        }
      }
      return true
    });
    setkitaplarInfo(filteredArray);
  }, [tumLıste, secilenYayinlar, secilenSiniflar, secilenBranslar, secilenKitapTurler, filterText]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }
    return text;
  };

  return (
    kitaplarInfo.length === 0 ?
      <div className="companyCardArea card">
        <h1 style={{ marginBottom: "59.2px", marginTop: "59.2px", textAlign: "center" }}>Filtrelerinize uygun sonuç bulunamadı.</h1>
      </div>
      :
      <div className="companyCardArea card">
        {kitaplarInfo.map((kitaplar, index) => (
          <div className="col-md-3 companyCard m-1 card" key={index}>
            {/* <div className="companyCardPhotoDiv">
              <img crossOrigin='anonymous'
                style={{ width: "100%" }}
                src={kitaplar.kitapKapakFoto ? kitaplar.kitapKapakFoto.ucUrl : acitasarım}
                alt="Kitap Kapak Fotoğraf"
                loading="lazy" // Lazy loading eklemek için
              />
            </div> */}
            <div className="companyCardPhotoDiv">
              <img
                style={{ width: "100%" }}
                src={kitaplar.kitapKapakFoto ? kitaplar.kitapKapakFoto.url : acitasarım}
                alt="Kitap Kapak Fotoğraf"
                loading="lazy" // Lazy loading eklemek için
              />
            </div>
            <div className="companyCardDetailsArea">

              <div className="companyCardDetailsCompanyName">
                <div className="d-flex flex-column align-items-center">
                  <h5>{truncateText(kitaplar.kitapAdi, 60)}</h5>
                </div>

              </div>

              <div>
                <FontAwesomeIcon icon={faBuilding} style={{ fontSize: "20px", width: "20px" }} />
                <p>{kitaplar.yayinAdi}</p>
              </div>

              <div>
                <FontAwesomeIcon icon={faGraduationCap} style={{ fontSize: "20px", width: "20px" }} />
                <p>{kitaplar.kitapSinif}</p>
              </div>

              <div>
                <FontAwesomeIcon icon={faBook} style={{ fontSize: "20px", width: "20px" }} />
                <p>{kitaplar.kitapBrans}</p>
              </div>

              <div>
                <FontAwesomeIcon icon={faBookOpen} style={{ fontSize: "20px", width: "20px" }} />
                <p>{kitaplar.kitapTur}</p>
              </div>

            </div>

            <div className=" btn companyCardDetailsAreaButton" onClick={() => { handleInceleClick(kitaplar) }}>
              İncele
            </div>

          </div>
        ))}
      </div>
  );


}

export default CompanyCard;