import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import "../Screens/css/KitapDetay.css";
import { listeler,kitapDetayIdListe } from "../axios";

// Iconlar
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faGraduationCap, faBookOpen, faFile, faCircleQuestion, faCalendarDay, faPenClip } from '@fortawesome/free-solid-svg-icons';
//Fotoğraflar Logolar
import bilgiseliLogo from "../Screens/img/bilgiseliyayinlarilogo.png";
import cevapLogo from "../Screens/img/cevapyayinlarilogo.png";
import efsaneLogo from "../Screens/img/efsaneyayinlarilogo.png";
import aciLogo from "../Screens/img/aciyayinlarilogo.png";


function KitapDetay() {
    const [kitapListe, setKitapListe] = useState([]);
    const [filtreKitap, setFiltreKitap] = useState({});
    const { kitapId } = useParams();
    const [yayinLogo, setYayinLogo] = useState();

    const navigate = useNavigate();


    useEffect(() => {
        kitapDetayIdListe(kitapId)
            .then((res) => {
                setFiltreKitap(res.data);
                setYayinLogoFromYayinAdi(res.data.yayinAdi);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [kitapId]);

    const setYayinLogoFromYayinAdi = (yayinAdi) => {
        switch(yayinAdi) {
            case "CEVAP YAYINLARI":
                setYayinLogo(cevapLogo);
                break;
            case "BİLGİSELİ YAYINLARI":
                setYayinLogo(bilgiseliLogo);
                break;
            case "EFSANE YAYINLARI":
                setYayinLogo(efsaneLogo);
                break;
            case "AÇI YAYINLARI":
                setYayinLogo(aciLogo);
                break;
            default:
                setYayinLogo(null);
        }
    };

    return (
        <div className='kitapDetayContainer'>

            <div className='kitapDetayAlan'>

                {/* <div className='kitapDetayImageAlan'>
                    <img crossOrigin='anonymous' alt={filtreKitap.kitapAdi} className='kitapDetayImage' src={filtreKitap.kitapKapakFoto ? filtreKitap.kitapKapakFoto.ucUrl : null} />
                    <button onClick={()=> navigate(`/kitapdetay/kitapdemo/${filtreKitap._id}`)} className='btn kitapDetayImageIncele'>İncele</button>
                </div> */}

                <div className='kitapDetayImageAlan'>
                    <img
                        alt={filtreKitap && filtreKitap.kitapAdi ? filtreKitap.kitapAdi : "Kitap Fotoğraf"}
                        className='kitapDetayImage'
                        src={filtreKitap && filtreKitap.kitapKapakFoto ? filtreKitap.kitapKapakFoto.url : null}
                    />
                    <button onClick={() => navigate(`/kitapdetay/kitapdemo/${filtreKitap._id}`)} className='btn kitapDetayImageIncele'>İncele</button>
                </div>

                <div className='kitapDetayBilgilerAlan'>
                    <div className='kitapDetayBilgilerBaslikAlan'>
                        <h3 className='kitapDetayBilgilerBaslikAlanKitapAdi'> {filtreKitap.kitapAdi}</h3>
                        <img alt="Yayın Evi Logo" className='kitapDetayBilgilerBaslikAlanLogo' src={yayinLogo} />
                        <button onClick={() => navigate(`/kitapdetay/kitapdemo/${filtreKitap._id}`)} className='btn kitapDetayBilgilerBaslikAlanIncele'>İncele</button>
                    </div>
                    <div className='kitapDetayBilgilerInfoAlan'>

                        <div className='kitapDetayBilgilerInfo1'>

                            <div className='kitapDetayBilgilerInfo1KitapBrans'>
                                <FontAwesomeIcon className='kitapDetayBilgilerFaIcon' icon={faBook} />
                                <h5 className='kitapDetayBilgilerYazı'>{filtreKitap.kitapBrans}</h5>
                            </div>
                            <div className='kitapDetayBilgilerInfo1KitapTur'>
                                <FontAwesomeIcon className='kitapDetayBilgilerFaIcon' icon={faBookOpen} />
                                <h5 className='kitapDetayBilgilerYazı'>{filtreKitap.kitapTur}</h5>
                            </div>
                        </div>

                        <div className='kitapDetayBilgilerInfo2'>
                            <div className='kitapDetayBilgilerInfo1KitapSinif'>
                                <FontAwesomeIcon className='kitapDetayBilgilerFaIcon' icon={faGraduationCap} />
                                <h5 className='kitapDetayBilgilerYazı'>{filtreKitap.kitapSinif}</h5>
                                <h5 className='kitapDetayBilgilerYazı'>{null}</h5>
                            </div>
                            <div className='kitapDetayBilgilerInfo2KitapSayfaSayisi'>
                                <FontAwesomeIcon className='kitapDetayBilgilerFaIcon' icon={faFile} />
                                <h5 className='kitapDetayBilgilerYazı'>{filtreKitap.kitapSayfaSayisi ? filtreKitap.kitapSayfaSayisi : "-"}</h5>
                                <h5 className='kitapDetayBilgilerYazı'>Sayfa</h5>
                            </div>
                            <div className='kitapDetayBilgilerInfo2KitapSoruSayisi'>
                                <FontAwesomeIcon className='kitapDetayBilgilerFaIcon' icon={faCircleQuestion} />
                                <h5 className='kitapDetayBilgilerYazı'>{filtreKitap.kitapSoruSayisi ? filtreKitap.kitapSoruSayisi : "-"}</h5>
                                <h5 className='kitapDetayBilgilerYazı'>Soru</h5>
                            </div>
                            <div className='kitapDetayBilgilerInfo2KitapCikisYili'>
                                <FontAwesomeIcon className='kitapDetayBilgilerFaIcon' icon={faCalendarDay} />
                                <h5 className='kitapDetayBilgilerYazı'>{filtreKitap.kitapCikisYili ? filtreKitap.kitapCikisYili : "-"}</h5>
                                <h5 className='kitapDetayBilgilerYazı'>Yıl</h5>
                            </div>
                            <div className='kitapDetayBilgilerInfo2KitapYazari'>
                                <FontAwesomeIcon className='kitapDetayBilgilerFaIcon' icon={faPenClip} />
                                <h5 className='kitapDetayBilgilerYazı'>{filtreKitap.kitapYazari ? filtreKitap.kitapYazari : "-"}</h5>
                                <h5 className='kitapDetayBilgilerYazı'>Yazar</h5>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </div>
    );
}

export default KitapDetay;