import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { setJustsetEkle } from "../../axios/index";

// MODAL CSS 
import "../css/Modal.css";
// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';


const SetJustsetAddScreen = (props) => {

    //MODAL DEĞİŞKENLERİ
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShowAndId = (id) => {
        handleShow();
        setIdSet(id);
        console.log(id)
        console.log(idSet)
    };

    const [formData, setFormData] = useState({
        setAdi: "",
        setKapakFoto: [],
        setYonlendirme: "",
    });

    const [idSet, setIdSet] = useState();

    const formDataSıfırla = () => {
        setFormData({
            setAdi: "",
            setKapakFoto: null,
            setYonlendirme: "",
        });
        console.log("form sıfırlandı");
        console.log(formData);
    };

    const handleImage = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, setKapakFoto: file })
        console.log(file)
    }

    // BU SAYFADA formData'nın içini doldurup VERİTABANINA YOLLAMAK İÇİN HAZIR EDİYORUZ


    return (
        <>

            <FontAwesomeIcon
                style={{ fontSize: "60px", color: "black" }}
                className="me-3"
                icon={faPlus}
                size="1x"
                onClick={() => handleShowAndId(props.setler._id)}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Ekleme</Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12} md={9}>
                                <Form
                                    encType="multipart/form-data"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        const toastId = toast.loading("Yeni set oluşturuluyor...");
                                        console.log(idSet,formData)
                                        setJustsetEkle(idSet,formData)
                                            .then((res) => {
                                                console.log(res);
                                                // İşlem tamamlandığında modalı kapat
                                                handleClose();
                                                toast.update(toastId, { render: 'Set başarıyla oluşturuldu ! 👌', type: toast.TYPE.SUCCESS, isLoading: false });
                                                formDataSıfırla();
                                                toast.dismiss();
                                            })
                                            .catch((err) => {
                                                console.error("İstek hatası: ", err);
                                                toast.update(toastId, { render: 'Set oluşturma başarısız ! 🤯', type: toast.TYPE.ERROR, isLoading: false });
                                                toast.dismiss();
                                              });
                                    }}
                                >

                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>Set Adı</Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                setFormData({ ...formData, setAdi: e.target.value })
                                            }
                                            type="name"
                                            placeholder="Set Adını Giriniz"
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>Set Kapak Fotoğraf</Form.Label>
                                        <Form.Control
                                            onChange={handleImage}
                                            type="file"
                                            name="setKapakFoto"
                                            placeholder="Set Fotoğrafı Ekleyiniz"
                                            crossOrigin="anonymous"
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>Set Yönlendirme</Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                setFormData({ ...formData, setYonlendirme: e.target.value })
                                            }
                                            type="name"
                                            placeholder="Yönlendirme Linkini Giriniz"
                                        />
                                    </Form.Group>

                                    <Form.Group className="d-grid">

                                        <Button variant="primary" type="submit" className="mt-4 w-100">
                                            Kayıt Ekle
                                        </Button>

                                        <Button onClick={handleClose} variant="secondary" className="mt-4 w-100">
                                            Vazgeç
                                        </Button>

                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Container>


                </Modal.Body>


            </Modal>
        </>
    );


};
export default SetJustsetAddScreen;
