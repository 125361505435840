import React, { useState, useEffect } from "react";
import { setListele } from "../../axios";

import SetAddScreen from "./SetAddScreen";
import SetJustsetAddScreen from "./SetJustsetAddScreen"
import SetJustsetDeleteScreen from "./SetJustsetDeleteScreen"
import SetJustsetUpdateScreen from "./SetJustsetUpdateScreen"
import SetYayinUpdate from "./SetYayinUpdate"
import SetYayinDeleteScreen from "./SetYayinDeleteScreen"


import { useNavigate } from "react-router-dom";

// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "../css/SetScreen.css";



function SetScreen() {

  const [tumLıste, setTumLıste] = useState([]);

  useEffect(() => {
    setListele()
      .then((res) => {
        setTumLıste(res.data)
        console.log(tumLıste)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  const navigate = useNavigate();

  return (
    <div className="container setScreenAnaDiv">

      <div className="setScreenFilterDiv">
      <SetAddScreen/>
      </div>


      <div className="setScreenSetlerDiv">

        {tumLıste.map((setler) => {
          return (
            <div className="setScreenSet">
              <div className="setScreenSetYayınAdi">
                <h4>{setler.yayinAdi}</h4>
                <SetYayinUpdate setler={setler} />
                <SetYayinDeleteScreen setler={setler}/>
              </div>
              <div className="setScreenSetCardDiv">

                <div className="setScreenSetCard">
                  <SetJustsetAddScreen setler={setler}/>
                </div>
                
                {setler.bilgilerSet.map((bilgilersetmap) => {
                  return(
                    <div className="setScreenSetCard">
                    <img crossOrigin="anonymous" alt={`${bilgilersetmap.setAdi} Kapak Fotoğrafı`} src={bilgilersetmap.setKapakFoto.ucUrl} onClick={()=> navigate(bilgilersetmap.setYonlendirme)}/>
                    <p style={{ color: "black" }}>{bilgilersetmap.setAdi}</p>
                    <div className="setScreenGuncelleSil">
                      <SetJustsetUpdateScreen setler={setler} bilgilersetmap={bilgilersetmap}/>
                      <SetJustsetDeleteScreen setler={setler} bilgilersetmap={bilgilersetmap}/>
                    </div>
                  </div>
                  )                 
                })}

              </div>

            </div>
          )

        })}

      </div>
      <ToastContainer/>
    </div>



  )


}

export default SetScreen;