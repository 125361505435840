// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal{
    width: 100%;
    /* display: flex !important;
    align-items: center;
    justify-content: center; */
}
.modal-dialog{
    width: 60%;
    max-width: 800px;
    color: rgb(255, 255, 255);
    margin-top: 100px;
}
.modal-content{
    background-color: #5c5c5c;
}`, "",{"version":3,"sources":["webpack://./src/adminekran/Screens/css/Modal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX;;8BAE0B;AAC9B;AACA;IACI,UAAU;IACV,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;AACrB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".modal{\n    width: 100%;\n    /* display: flex !important;\n    align-items: center;\n    justify-content: center; */\n}\n.modal-dialog{\n    width: 60%;\n    max-width: 800px;\n    color: rgb(255, 255, 255);\n    margin-top: 100px;\n}\n.modal-content{\n    background-color: #5c5c5c;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
