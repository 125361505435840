import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Screens/img/egitimiceriklogo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faBuilding, faPeopleGroup, faPhone, faBook, faBookOpen } from '@fortawesome/free-solid-svg-icons';
import "../Screens/css/Navbar.css";

const Header = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const navbarRef = useRef(null);
  const containerRef = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isNavbarOpen && !containerRef.current.contains(event.target)) {
        setIsNavbarOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isNavbarOpen]);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handleLinkClick = () => {
    setIsNavbarOpen(false);
  };

  return (
    <nav ref={scrollRef} className="navbar navbar-expand-lg navbar-light bg-light">
      <div ref={containerRef} className="container navbarContainer">
        <Link to="/" style={{ textDecoration: "none" }} className="navbar-brand">
          <img src={logo} alt="Eğitim İçerik Logo" style={{ width: "130px", marginRight: "50px" }}></img>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={isNavbarOpen ? "true" : "false"}
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse flex-grow-0 pt-2${isNavbarOpen ? " show" : ""}`} id="navbarSupportedContent" ref={navbarRef}>
          <ul className="navbar-nav navbarNav me-auto mb-2 mb-lg-0">
            <Link to="/" onClick={handleLinkClick} className="nav-link navLink active" aria-current="page">
              <li className="nav-item navbarItem  me-3">
                <FontAwesomeIcon icon={faHouse} style={{ fontSize: "22px" }} />
                <h6 className="mt-1 ms-2">Anasayfa</h6>
              </li>
            </Link>

            <Link to="/kitaplar" onClick={handleLinkClick} className="nav-link navLink active" aria-current="page">
              <li className="nav-item navbarItem me-3">
                <FontAwesomeIcon icon={faBookOpen} style={{ fontSize: "22px" }} />
                <h6 className="mt-1 ms-2">Kitap Listesi</h6>
              </li>
            </Link>

            <Link to="/iletisim" onClick={handleLinkClick} className="nav-link navLink active" aria-current="page" style={{border:"none"}}>
            <li className="nav-item navbarItem  me-3">
              <FontAwesomeIcon icon={faPhone} style={{ fontSize: "22px" }} />
              <h6 className="mt-1 ms-2">İletişim</h6>
            </li>
            </Link>

          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
