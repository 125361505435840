import axios from "axios";

const HTTP = axios.create({
  // baseURL: "https://demokitapbackend.onrender.com",
  baseURL: "https://ornekadmin.egitimdijital.com",
  // baseURL: "http://localhost:5000",
});

// Admin login screen Alanı
export const login = async (formData) =>
  await HTTP.post("/users/signin", formData);


// Kitap Listesi Alanı
export const register = async (formData) =>
  await HTTP.post("/kitapbilgilers/aciadmin/liste/add", formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Content-Type'ı ayarlayın
    },
  });

export const listeler = async () =>
  await HTTP.get("/kitapbilgilers/aciadmin/liste");

export const update = async (id, updatedFormData) =>
  await HTTP.put(`/kitapbilgilers/aciadmin/liste/update/${id}`, updatedFormData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Content-Type'ı ayarlayın
    },
  });

export const deleteKitap = async (id) =>
  await HTTP.delete(`/kitapbilgilers/aciadmin/liste/delete/${id}`);

// Set Listesi Alanı

export const setListele = async () =>
  await HTTP.get("/setbilgilers/aciadmin/setler/listele");

export const setEkle = async (formData) =>
  await HTTP.post("/setbilgilers/aciadmin/setler/add", formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Content-Type'ı ayarlayın
    },
  });

export const setYayinGuncelle = async (yayinId, updatedFormData) => {
  console.log(yayinId, updatedFormData);
  try {
    const response = await HTTP.put(`/setbilgilers/aciadmin/setler/yayinguncelle/${yayinId}`, updatedFormData);
    return response.data; // İşlem başarılıysa sunucudan gelen veriyi döndür
  } catch (error) {
    throw error; // Hata durumunda hatayı yönlendir
  }
};

export const setYayinSil = async (yayinId) => {
  console.log(yayinId);
  await HTTP.delete(`/setbilgilers/aciadmin/setler/yayinsil/${yayinId}`);
};

export const setJustsetEkle = async (id, formData) =>
  await HTTP.put(`/setbilgilers/aciadmin/setler/yeniseteleman/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Content-Type'ı ayarlayın
    },
  });

export const setElemanUpdate = async (yayinId, setId, updatedFormData) => {
  await HTTP.put(`/setbilgilers/aciadmin/setler/update/setelemanguncelle/${yayinId}/${setId}`, updatedFormData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Content-Type'ı ayarlayın
    },
  })
};

export const setElemanDelete = async (yayinId, setId) => {
  await HTTP.delete(`/setbilgilers/aciadmin/setler/delete/setelemansil/${yayinId}/${setId}`);
}



// Feedback alanı
export const feedbackAdd = async (formData) =>
  await HTTP.post("/feedbacks/aciadmin/messages", formData);

export const feedbackListe = async () =>
  await HTTP.get("/feedbacks/aciadmin/messages");

