import axios from "axios";

const HTTP = axios.create({
  // baseURL: "https://demokitapbackend.onrender.com",
  baseURL: "https://ornekadmin.egitimdijital.com",
  // baseURL: "http://localhost:5000",
});

export const login = async (formData) =>
  await HTTP.post("/users/signin", formData);

export const register = async (formData) =>
  await HTTP.post("/kitapbilgilers/aciadmin/liste/add", formData);

export const listeler = async () =>
  await HTTP.get("/kitapbilgilers/aciadmin/liste");

export const kitapDetayIdListe = async (id) =>
  await HTTP.get(`kitapbilgilers/aciadmin/liste/kitapdetay/${id}`);


  export const update = async (id,updatedFormData) =>
   await HTTP.put(`/kitapbilgilers/aciadmin/liste/update/${id}`,updatedFormData);

   export const deleteKolej = async (id) =>
   await HTTP.delete(`/kitapbilgilers/aciadmin/liste/delete/${id}`);


   // Set Bilgilerinin axios'u


   export const setListele = async () =>
   await HTTP.get("/setbilgilers/aciadmin/setler/listele");

   // Türkiyedeki iller ve ilçelerini api olarak alıyoruz.
   export const fetchUsers = async () =>
    await HTTP.get("https://turkiyeapi.cyclic.app/api/v1/provinces");

    export const feedbackAdd = async (formData) =>
    await HTTP.post("/feedbacks/aciadmin/messages", formData);

    export const feedbackListe = async () =>
    await HTTP.get("/feedbacks/aciadmin/messages");

