import React from "react";
import "./css/HomeScreen.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faChartSimple,faMessage,faChartBar} from '@fortawesome/free-solid-svg-icons';


const HomeScreen = ({ user }) => {


  return(
    <div>
      <div className="cardAlan">

        <div className="card cardAlan-card bg-danger">
        <FontAwesomeIcon icon={faChartSimple} size="2x" />
        <p><strong>2554</strong></p>
        <p>Toplam Ziyaretçi</p>
        </div>

        <div className="card cardAlan-card bg-primary">
        <FontAwesomeIcon icon={faBuilding}  size="2x" />
        <p><strong>50</strong></p>
        <p>Kayıtlı Kurum</p>
        </div>

        <div className="card cardAlan-card bg-success">
        <FontAwesomeIcon icon={faMessage}  size="2x" />
        <p><strong>30</strong></p>
        <p>Form Mesajı</p>
        </div>

        <div className="card cardAlan-card bg-indigo">
        <FontAwesomeIcon icon={faChartBar}  size="2x" />
        <p><strong>2</strong></p>
        <p>Yayınlanan Haber</p>
        </div>



        <div className="grafikAlan">
  
        </div>


      </div>
      {user?.email}
    </div>
  ) 
};

export default HomeScreen;
