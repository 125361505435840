import React from "react";
import { setYayinSil } from "../../axios/index";

// BİLDİRİM İÇİN PAKET
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const SetYayinDeleteScreen = (props) => {

    const handleDelete = async (yayinId, yayinAdi) => {
        console.log(
           `Yayın Id": ${yayinId},
            Yayın Adı": ${yayinAdi},
            `
            )
        const confirmDelete = window.confirm(`${yayinAdi} adlı yayını silmek istediğinize emin misiniz ?`);
        if (confirmDelete) {
            if(props.setler.bilgilerSet.length <= 0){
                setYayinSil(yayinId)
                .then((res) => {
                toast.success(`${yayinAdi} adlı yayın başarıyla silindi!`);
                  console.log(res.data)
                })
                .catch((err) => {
                    console.error(`Set eleman silme başarısız`);
                  });
            }
            else{
                toast.error("Lütfen ilk önce setleri siliniz !");
            }
        }
      }
      
    return (
        <>
            <FontAwesomeIcon
                style={{ fontSize: "20px", color: "black" }}
                className="me-3"
                icon={faTrash}
                size="1x"
                onClick={() => handleDelete(props.setler._id,props.setler.yayinAdi)}
            />
        </>
    );


};
export default SetYayinDeleteScreen;
