import React from 'react'
import { Typewriter } from 'react-simple-typewriter'

 const TypeWriter = () => {

  return (
    <div className='typeWriterDiv'>
      <h1 style={{ paddingTop: '5rem', margin: 'auto 0', fontWeight: 'normal' }}>
        Eğitim İçerik ile{' '}
        <br/>
        <span style={{ color: 'rgba(227,5,18,255)', fontWeight: 'bold' }}>
          {/* Style will be inherited from the parent element */}
          <Typewriter
            words={['Akıllı Tahta Uygulamaları', 'Video Çözümler', 'Kitap Örnekleri', ]}
            loop={5}
            cursor
            cursorStyle='_'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </span>
      </h1>
    </div>
  )
  }
  export default TypeWriter;