import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { listeler,kitapDetayIdListe } from "../axios/index";

import "../Screens/css/KitapDemo.css"
import HTMLFlipBook from 'react-pageflip';
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";


import "./css/KitapDemo.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleRight, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faXmark } from '@fortawesome/free-solid-svg-icons';

const KitapDemo = () => {

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const bookHeight = viewportHeight * 0.70; // 80% of the viewport height
  const bookWidth = Math.round(bookHeight * 0.715); // bookWidth'i yuvarla


  const [filtreKitap, setFiltreKitap] = useState();
  const { kitapId } = useParams();
  const [onloadOlduMu, setOnloadOlduMu] = useState(false);

  const [page, setPage] = useState(0);
  const totalPageCount = filtreKitap?.demoKitapPdf.length || 0;
  const minPage = 0;
  const flipBookRef = useRef(null);
  const pageNumberText = useRef(null);

  const [isMobile, setIsMobile] = useState(false);

  const [isZoomClosed, setIsZoomClosed] = useState(true);

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();

    return (
      <div className="tools d-flex justify-content-center">
        <button className='prenextButton' onClick={() => {
          zoomIn();
          setIsZoomClosed(false)
        }}><FontAwesomeIcon className='me-2' icon={faMagnifyingGlassPlus} /></button>
        <button className='prenextButton' onClick={() => {
          zoomOut();
          setIsZoomClosed(true);
        }}><FontAwesomeIcon className='me-2' icon={faMagnifyingGlassMinus} /></button>
        <button className='prenextButton' onClick={() => {
          resetTransform()
          setIsZoomClosed(true);
        }}>Reset</button>
      </div>
    );
  };

  useEffect(() => {
    const checkMobile = () => {
      let check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      setIsMobile(check);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount


  useEffect(() => {
    kitapDetayIdListe(kitapId)
      .then((res) => {

       const filteredKitap = res.data; // API cevabı tek bir kitap olduğu için doğrudan kullanabiliriz.

        if (filteredKitap) {
          // Sort demoKitapPdf array by page number extracted from URL
          const sortedDemoKitapPdf = filteredKitap.demoKitapPdf.sort((a, b) => {
            const pageNumberA = extractPageNumber(a.url);
            const pageNumberB = extractPageNumber(b.url);
            return pageNumberA - pageNumberB;
          });
          setFiltreKitap({ ...filteredKitap, demoKitapPdf: sortedDemoKitapPdf });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [kitapId]);


  const gotoNextPage = () => {
    if (page < totalPageCount) {
      flipBookRef.current.pageFlip().flipNext();
    }
    else {
      flipBookRef.current.pageFlip().flipNext();
    }
  };

  const gotoPrevPage = () => {
    if (page > 1) {
      flipBookRef.current.pageFlip().flipPrev();
    }
    else {
      flipBookRef.current.pageFlip().flipPrev();
    }
  };

  const gotoPage = (pageNum) => {
    const pageNumber = parseInt(pageNum, 10); // String'i sayıya dönüştür
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPageCount) { // Eğer input'a girilen değer geçersiz ise ilk sayfaya yönlendirir.
      flipBookRef.current.pageFlip().turnToPage(pageNumber);
    }
    else {
      flipBookRef.current.pageFlip().turnToPage(0);
    }
  };

  if (pageNumberText.current) {
    pageNumberText.current.value = page;
  }

  const pageWidth = document.body.offsetWidth; // Sayfanın genişliğini al
  const pageHeigth = document.body.offsetHeight;
  const thresholdWidth = 1010; // Kontrol etmek istediğiniz genişlik

  const onFlip = useCallback((e) => {
    const stfBlock = document.querySelector('.stf__block');
    if (!stfBlock) return; // Element bulunamazsa işlemi durdur

    // Eğer sayfa 0'da ise ekrana 0.sayfayı ekrana ortalar.
    if (e.data === 0) {
      pageNumberText.current.value = e.data;
      stfBlock.style.left = "-250px";
    } else {
      stfBlock.style.left = "0px";

      // Eğer sayfanın genişliği 1010'dan küçükse (mobil hali) sayfa numaraları tekli olarak yazılacak 1,2 gibi
      // Değilse 1-2 , 3-4 gibi ikişerli yazılacak
      if (pageWidth < thresholdWidth) {
        pageNumberText.current.value = `${e.data}`;
      } else {
        pageNumberText.current.value = `${e.data}-${e.data + 1}`;
      }
    }
  }, [pageWidth, thresholdWidth]);



  const toggleZoom = () => {
    setIsZoomClosed(!isZoomClosed);
  };

  // const extractPageNumber = (url) => {
  //   // Example URL: "Kitap Demo Pdfler/E FASİKÜL DENEME CLOUD/3.png"
  //   const matches = url.match(/(\d+)\.png$/); // Extracts digits before .png
  //   return matches ? parseInt(matches[1], 10) : 0;
  // };

  const extractPageNumber = (url) => {
    // URL içindeki `.png` öncesinde kısa sayıyı yakala
    const matches = url.match(/(\d+)-\d+\.png$/);
    // İlk yakalanan grubu sayfa numarası olarak al
    return matches ? parseInt(matches[1], 10) : 0;
  };


  return (
    <TransformWrapper disabled={isZoomClosed}  >
      <Controls />
      <TransformComponent>
        <div className="flip-book-container" >

          {filtreKitap && (
            <>
              <h1 className='flipBookBookName'>{filtreKitap.kitapAdi}</h1>
              {/* {pageWidth < thresholdWidth ? null :
                <button className='prenextButton mb-2' onClick={toggleZoom}>{`Yakınlaştırma ve Kaydırma Modunu ${isZoomClosed ? "Aç" : "Kapat"}`}</button>
              } */}

              <HTMLFlipBook
                width={bookWidth}
                height={bookHeight}
                pageIndex={page - 1} // Sayfa numarasını belirle
                ref={flipBookRef}
                onFlip={onFlip}
                showCover={true} // İlk ve son sayfa tek sayfa olarak gösteriliyor
                disableFlipByClick={pageWidth < thresholdWidth ? false : true}
              >

                <div className='demoPage bookFirstPage'>

                  {/* <img crossOrigin='anonymous' style={{ width: "100%", height: "100%" }} src={filtreKitap.kitapKapakFoto.ucUrl} alt={`${filtreKitap.kitapAdi} Kapak`} /> */}

                  <img style={{ width: "100%", height: "100%" }} src={filtreKitap.kitapKapakFoto.url} alt={`${filtreKitap.kitapAdi} Kapak`} />

                </div>

                {filtreKitap.demoKitapPdf.map((demoURL, index) => (
                  <div key={index} className="demoPage">

                    <img style={{ width: "100%", height: "100%" }} src={demoURL.url} alt={`Page ${index + 1}`} />

                  </div>
                ))}

              </HTMLFlipBook>

              <div className='pageForwardDiv'>
                <button className='previousPage prenextButton' onClick={gotoPrevPage} disabled={page === 1}>
                  <FontAwesomeIcon className='me-2' icon={faAngleDoubleLeft} />
                  Önceki Sayfa
                </button>

                <span className='pageNumberTextSpan'>
                  <input
                    className='pageNumberText'
                    ref={pageNumberText}
                    type="text"
                    min={minPage} // 0
                    max={totalPageCount}
                    onBlur={(e) => {
                      const pageNumber = e.target.value === "" ? "1-2" : e.target.value;
                      gotoPage(pageNumber);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') { // Entera basıldığında onBlur'un odağı değiştiriyoruz. O'da bizi sayfaya yönlendiriyor.
                        e.preventDefault(); // Enter tuşunun varsayılan davranışını engelle
                        e.target.blur();
                      }
                    }}
                  /> / {totalPageCount}
                </span>

                <button className='nextPage prenextButton' onClick={gotoNextPage} disabled={page === totalPageCount}>
                  Sonraki Sayfa
                  <FontAwesomeIcon className='ms-2' icon={faAngleDoubleRight} />
                </button>

              </div>
            </>
          )}



          {/* <div className={onloadOlduMu ? "d-none" : "loading-overlay"}>
  Yükleniyor...
</div> */}

        </div>
      </TransformComponent>
    </TransformWrapper>

  );


};

export default KitapDemo;
