import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import logo from "../Screens/img/egitimiceriklogo.png";
import { Link } from 'react-router-dom';
import "../Screens/css/Footer.css";

export default function App() {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted footerArea' style={{width:"100%"}}>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Bize sosyal medyadan ulaşabilirsiniz:</span>
        </div>

        <div>
          <a href='https://www.facebook.com/aciegitimkurumlari/?locale=tr_TR' className='me-4 text-reset' target='blank'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='https://twitter.com/aci_egitim' className='me-4 text-reset' target='blank'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='https://www.instagram.com/aciegitimkurumlari/' className='me-4 text-reset' target='blank'>
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='https://tr.linkedin.com/company/a%C3%A7%C4%B1l%C4%B1m-e%C4%9Fitim-%C3%B6%C4%9Fretim-a-%C5%9F' className='me-4 text-reset' target='blank'>
            <MDBIcon fab icon="linkedin" />
          </a>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4' width="100%">
              <img className='footerAciLogo' src={logo} alt='Açı Eğitim Kurumları Logo' width={"100%"}/>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Kategoriler</h6>

              <p>
                <Link to="/" className='text-reset'>
                Anasayfa
                </Link>
              </p>

              <p>
                <Link to="/kitaplar" className='text-reset'>
                Kitap Listesi
                </Link>
              </p>
              <p>
                <Link to="/hakkımızda" className='text-reset'>
                Hakkımızda
                </Link>
              </p>
              <p>
                <Link to="/iletisim" className='text-reset'>
                Mesaj İstek Öneri ve Sorular
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Yayınlar</h6>
              <p>
                <a href='https://bilgiseli.com.tr' className='text-reset' target='blank'>
                  Bilgiseli Yayınları
                </a>
              </p>
              <p>
                <a href='https://cevapyayinlari.com' className='text-reset' target='blank'>
                  Cevap Yayınları
                </a>
              </p>
              <p>
                <a href='https://efsaneyayinlari.com' className='text-reset' target='blank'>
                  Efsane Yayınları
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>İletişim</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Korkutreis, Yeşilırmak Cd. No:15, 06430 Çankaya/Ankara
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                iletisim@egitimicerik.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> 0312-231-7541
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2023 Copyright: <strong>Eğitim İçerik</strong> Tüm Hakları Saklıdır.
      </div>
    </MDBFooter>
  );
}
