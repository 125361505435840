import React, { useState, useEffect } from "react";
import { feedbackListe } from "../axios";

// !!! DATATABLE İMPORTLAR !!!

import Table from "./Table/FeedbackTable";
import "./Table/styles.css";

// BİLDİRİM İÇİN PAKET
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function FeedbackAdmin() {


  const [tumLıste, setTumLıste] = useState([]);

  useEffect(() => {
    feedbackListe()
      .then((res) => {
        setTumLıste(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])


return(
  <>
  <Table data={tumLıste}/>
  <ToastContainer/>
  </>


)


}

export default FeedbackAdmin;