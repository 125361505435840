// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setScreenAnaDiv{
    min-height: 90vh;
    background-color: rgb(255, 255, 255);
}
.sliderImage{
    width: 50%;
}
.setScreenSetlerDiv{
    width: 100%;
}
.setScreenFilterDiv{
    padding: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid black;
}
.setScreenSet{
    padding: 20px;
}
.setScreenSetCardDiv{
    display: flex;
}
.setScreenSetCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    width: 20%;
    padding: 1.0rem;
    box-shadow: 0 -0.5rem 1rem rgba(0 0 0 / 0.15);
    border-radius: 4px;
}
.setScreenSetCard img{
    width: 100%;
}
.setScreenGuncelleSil{
    display: flex;
}

.setScreenSetYayınAdi{
    display: flex;
    align-items: baseline;
}
.setScreenSetYayınAdi h4{
    color: black;
    margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/adminekran/Screens/css/SetScreen.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oCAAoC;AACxC;AACA;IACI,UAAU;AACd;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,6CAA6C;IAC7C,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".setScreenAnaDiv{\r\n    min-height: 90vh;\r\n    background-color: rgb(255, 255, 255);\r\n}\r\n.sliderImage{\r\n    width: 50%;\r\n}\r\n.setScreenSetlerDiv{\r\n    width: 100%;\r\n}\r\n.setScreenFilterDiv{\r\n    padding: 20px;\r\n    margin-bottom: 20px;\r\n    border-bottom: 2px solid black;\r\n}\r\n.setScreenSet{\r\n    padding: 20px;\r\n}\r\n.setScreenSetCardDiv{\r\n    display: flex;\r\n}\r\n.setScreenSetCard{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-right: 20px;\r\n    width: 20%;\r\n    padding: 1.0rem;\r\n    box-shadow: 0 -0.5rem 1rem rgba(0 0 0 / 0.15);\r\n    border-radius: 4px;\r\n}\r\n.setScreenSetCard img{\r\n    width: 100%;\r\n}\r\n.setScreenGuncelleSil{\r\n    display: flex;\r\n}\r\n\r\n.setScreenSetYayınAdi{\r\n    display: flex;\r\n    align-items: baseline;\r\n}\r\n.setScreenSetYayınAdi h4{\r\n    color: black;\r\n    margin-right: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
